@import url("https://use.typekit.net/whe0xte.css");
@import url("https://fonts.googleapis.com/css?family=Muli:300,400,700&display=swap");
html.webshop--isproductpage {
  overflow-x: hidden;
}
html.webshop--isproductpage .site-wrapper {
  background-color: #f5f8fb !important;
}
html.webshop--isproductpage main {
  width: 100%;
}
html.webshop--isproductpage .badge-danger {
  padding: 10px 20px;
  font-size: 18px;
  background-color: #d40000;
}

.slick-track {
  margin-left: 0;
  margin-right: auto;
}

.product__images {
  max-height: 630px;
  overflow: hidden;
}
@media (max-width: 577px) {
  .product__images {
    margin: 20px 0;
  }
  .product__images .slick-next,
  .product__images .slick-prev {
    top: 100%;
    margin-top: 15px;
    z-index: 1;
  }
  .product__images .slick-next {
    right: 0;
  }
  .product__images .slick-prev {
    left: 0;
  }
}

.slick-next {
  right: auto;
  z-index: 1;
  position: inherit;
  margin: 0 auto;
  margin-top: 20px;
}
.slick-next:before {
  font-family: fontAwesome;
  content: "\f0ab";
  color: #e7bd62;
}

.slick-prev {
  left: auto;
  z-index: 1;
  position: inherit;
  margin: 0 auto;
}
.slick-prev:before {
  font-family: fontAwesome;
  content: "\f0aa";
  color: #e7bd62;
}

.product__options .card {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  border: 1px solid #ccc;
  border-radius: 3px;
  word-wrap: unset;
}
.product__options .card .card-body .product__deliverytime {
  display: none;
  text-align: center;
}
.product__options .card .card-body .product__deliverytime.tijdelijk-niet-leverbaar {
  display: block;
  color: #dc3545 !important;
}
.product__options .card .card-body .form-control {
  border-radius: 2px;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.product__options .card .card-body #amount_container {
  max-width: 164px;
  min-width: 139px;
}
.product__options .card .card-body .in-decrease_amount .value_button {
  display: inline-block;
  border: 1px solid #ccc;
  margin: 0px;
  width: 44px;
  height: 44px;
  text-align: center;
  vertical-align: middle;
  padding: 12px 0;
  background: #fdf8f4;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.product__options .card .card-body .in-decrease_amount .value_button:hover {
  cursor: pointer;
}
.product__options .card .card-body .in-decrease_amount #decrease {
  border-right: none;
  border-radius: 3px 0 0 3px;
}
.product__options .card .card-body .in-decrease_amount #increase {
  border-left: none;
  border-radius: 0 3px 3px 0;
}
.product__options .card .card-body .in-decrease_amount input#amount {
  text-align: center;
  border: none;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  margin: 0px;
  width: 44px;
  height: 44px;
  position: relative;
  top: 2px;
}
.product__options .card .card-body .in-decrease_amount input#amount::-webkit-inner-spin-button, .product__options .card .card-body .in-decrease_amount input#amount::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.product__options h1 {
  color: #005bbe !important;
  margin-bottom: 0;
}
.product__options .product_ean {
  color: #999;
}
.product__options .readmore__prod {
  text-decoration: underline;
  margin-bottom: 20px;
  display: block;
  margin-top: 10px;
}
.product__options .webshop-product__optiontitle {
  float: left;
  width: 100px;
  font-weight: 400;
  margin-bottom: 0;
  font-size: 13px;
}
.product__options .webshop-product__listprice {
  font-size: 19px;
  font-weight: 400;
  color: #d40000;
  position: relative;
}
.product__options .webshop-product__listprice:before {
  content: "";
  position: absolute;
  top: 11px;
  left: 0px;
  width: 100%;
  height: 9px;
  background: transparent;
  border-top: 1px solid #d40000;
  border-radius: 0;
  transform: rotate(-11deg);
}
.product__options .product__quantitydiscounts {
  padding: 10px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  border-radius: 3px;
  line-height: 21px;
}
.product__options .product__quantitydiscounts h4 {
  font-size: 21px;
  margin-bottom: 0;
}
.product__options .product__quantitydiscounts .quantitydiscounts_percentage {
  color: #66aa2f;
  font-weight: 600;
  float: right;
}
.product__options .webshop-product__price {
  font-size: 28px;
  font-weight: 600;
  color: #000;
}
.product__options .btn-lg {
  font-size: 15px;
  padding: 10px 20px;
}
.product__options .product__discountslogan {
  margin-top: 15px;
  color: #66aa2f;
  font-weight: 700;
}
.product__options .product_promoimage > img {
  max-width: 100%;
  height: auto;
}

.webshop-product__option--hiddendefault {
  display: none;
}

#product__tabs .tabs_container #nav-tabContent {
  clear: both;
}
#product__tabs .tabs_container .nav-pills_container {
  background-color: #f5f8fb !important;
  border-bottom: 1px solid #ccc;
  margin: 40px 0 0 0;
  height: 49px;
}
#product__tabs .tabs_container .nav-pills .nav-item.active {
  background-color: #fff;
  border-left: 1px solid #ccc;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #fff;
  border-right: 1px solid #ccc;
  margin-bottom: -1px;
  margin-top: 1px;
}
#product__tabs .tabs_container .nav-pills .nav-link {
  padding: 0.7rem 1rem;
  color: #005bbe !important;
}
#product__tabs .tabs_container .product__related {
  margin-top: 0;
  border-top: 1px solid #ccc;
  padding: 50px 0 0 0;
}
#product__tabs .tabs_container .product__related .productsgrid {
  margin: 0 !important;
  width: 100%;
}
#product__tabs .tabs_container .product__related h2 {
  margin-bottom: 15px;
}
#product__tabs .tabs_container .product__related .productitem {
  box-shadow: 1px 3px 6px 0 rgba(0, 0, 0, 0.12);
  border: 1px solid #ccc;
}
#product__tabs .tabs_container .product__related .productitem__body {
  padding: 15px;
}
#product__tabs .tabs_container .product__related .productitem__footer {
  padding: 0 15px 15px 15px;
  border-bottom: 0;
}
#product__tabs .tabs_container .product__related .productitem__footer .productitem__price {
  min-height: inherit;
}
#product__tabs .tabs_container .product__related .productitem__footer .btn {
  top: 0;
}
@media (max-width: 767px) {
  #product__tabs .tabs_container .product__related {
    margin-bottom: 30px;
  }
  #product__tabs .tabs_container .product__related h2 {
    margin-bottom: 10px;
  }
  #product__tabs .tabs_container .product__related .card-body {
    padding: 10px;
  }
}
#product__tabs .tabs_container .product__specs {
  padding: 50px 0;
}
@media (max-width: 767px) {
  #product__tabs .tabs_container .product__specs {
    padding: 0;
  }
}
#product__tabs .tabs_container .product__specs h2 {
  margin-bottom: 30px;
}
#product__tabs .tabs_container .product__spectable {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
  border-spacing: 0;
  border-collapse: collapse;
  border-color: grey;
}
#product__tabs .tabs_container .product__spectablecell {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding: 8px;
}
#product__tabs .tabs_container .product__spectablerow .product__spectablecell:first-child {
  border-right: 1px solid #ddd;
}
#product__tabs .tabs_container .product__spectablerow:nth-of-type(odd) {
  background-color: #f5f8fb !important;
}
@media (max-width: 992px) {
  #product__tabs {
    background-color: #fff;
  }
}

.webshop-product__stockinfo {
  color: #00b900;
  text-align: right;
  margin-top: 10px;
  font-weight: 600;
}
.webshop-product__stockinfo span {
  font-weight: 400;
}

.product__images__preview {
  text-align: center;
  display: flex;
  justify-content: center;
}
.product__images__preview a {
  display: inline-flex;
}
.product__images__preview img {
  height: auto;
  align-self: center;
}

.product__images__grid {
  margin: 20px 0;
  max-height: 100px;
  padding: 0;
}
@media (max-width: 480px) {
  .product__images__grid {
    margin: 10px 0 0 0;
  }
}
.product__images__grid .slick-slide {
  margin-right: 10px;
  opacity: 0.6;
}
.product__images__grid .slick-slide img {
  cursor: pointer;
  border: 2px solid #fff;
}
.product__images__grid .slick-slide.slick-current {
  opacity: 1;
}
.product__images__grid .slick-slide.slick-current img {
  border: 2px solid #fd8301;
}

/*
  Hide <select> once the replacement .dd-container is live
*/
#product__images__preview__container {
  clear: both;
  overflow: hidden;
}

.slick-list {
  max-width: 600px;
}

.dd-container + select {
  display: none;
}

.dd-selected {
  font-weight: normal;
  position: relative;
}

.dd-container {
  position: relative;
  margin-bottom: 0;
  top: -5px;
  -webkit-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  -moz-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.dd-selected-text,
.webshop-product__optiontitle {
  font-weight: 400;
  margin-bottom: 0;
  font-size: 13px;
}

.webshop-product__optionlabel {
  font-size: 13px;
  margin-bottom: 3px;
}

#image-preview .image-preview-title {
  display: block;
  text-align: center;
  padding: 3px 10px;
}

/* Large desktop */
@media (min-width: 1300px) {
  #image-preview {
    position: absolute;
    z-index: 2001;
    background-color: #fff;
    border: 5px solid #fff;
    -webkit-box-shadow: 0px 0px 7px 0px rgba(50, 50, 50, 0.4);
    -moz-box-shadow: 0px 0px 7px 0px rgba(50, 50, 50, 0.4);
    box-shadow: 0px 0px 7px 0px rgba(50, 50, 50, 0.4);
  }
  #image-preview img {
    max-width: 400px;
  }
}
/* Desktop */
@media (min-width: 979px) and (max-width: 1300px) {
  #image-preview {
    background-color: #fff;
    position: absolute;
    z-index: 2001;
    border: 5px solid #fff;
    -webkit-box-shadow: 0px 0px 7px 0px rgba(50, 50, 50, 0.4);
    -moz-box-shadow: 0px 0px 7px 0px rgba(50, 50, 50, 0.4);
    box-shadow: 0px 0px 7px 0px rgba(50, 50, 50, 0.4);
  }
  #image-preview img {
    max-width: 400px;
  }
}
@media (max-width: 992px) {
  .webshop--isproductpage .product__images {
    overflow: hidden;
  }
  .webshop--isproductpage .product__options {
    border: none;
    padding: 0;
    box-shadow: none !important;
  }
  .webshop--isproductpage .product__options .dd-container {
    margin-bottom: 0;
  }
}
@media (min-width: 480px) and (max-width: 979px) {
  .webshop-product__optiontitle {
    width: 100%;
    margin-bottom: 10px;
  }
}
@media (max-width: 577px) {
  .product__images .slick-next,
  .product__images .slick-prev {
    top: 100%;
    margin-top: 15px;
    z-index: 1;
  }
  .product__images .slick-next {
    right: 0px;
  }
  .product__images .slick-prev {
    left: 0px;
  }
}
.slick-next:before, .slick-prev:before {
  color: #fd8301;
}

.product__tags {
  margin-top: 20px;
  margin-bottom: 20px;
}

.product__tag {
  background: transparent;
  padding: 6px 10px;
  display: inline-block;
  margin: 2px;
  color: #bbb;
  border-radius: 3px;
  font-size: 14px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out 0s;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  font-weight: 400;
  text-decoration: none;
}

.product__tag:hover {
  background: #fff;
  text-decoration: none;
  border: 1px solid rgba(0, 0, 0, 0.2);
  color: inherit;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.2);
}

@media (min-width: 480px) and (max-width: 979px) {
  .webshop-product__optiontitle {
    width: 100%;
    margin-bottom: 10px;
  }
}
@media (max-width: 480px) {
  .nav-link {
    display: block;
    padding: 0.5rem;
  }
  .webshop-product__optiontitle {
    width: 100%;
    margin-bottom: 10px;
  }
  .product__options {
    margin-bottom: 30px;
  }
}
@media (min-width: 768px) {
  .webshop-product__priceblock__column {
    min-width: 139px;
  }
}
.product__related .product_listing {
  padding-bottom: 40px;
  margin-bottom: 0;
}
@media (max-width: 576px) {
  .product__related .product_listing {
    margin: 0;
  }
  .product__related .product_listing .col-lg-4 {
    width: 100%;
  }
}
@media only screen and (min-width: 577px) and (max-width: 768px) {
  .product__related .product_listing .col-lg-4 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media (min-width: 992px) {
  .product__related .product_listing .col-lg-4 {
    flex: 0 0 25%;
    max-width: 25%;
  }
}
@media (min-width: 1200px) {
  .product__related .product_listing .col-lg-4 {
    flex: 0 0 25%;
    max-width: 25%;
  }
}
@media (min-width: 1400px) {
  .product__related .product_listing .col-lg-4 {
    flex: 0 0 25%;
    max-width: 25%;
  }
}