@import url("https://use.typekit.net/whe0xte.css");
@import url("https://fonts.googleapis.com/css?family=Muli:300,400,700&display=swap");
html.webshop--ishomepage .site-wrapper {
  background-color: #fff !important;
}
html.webshop--ishomepage h1 b {
  font-weight: 500;
  font-size: 32px;
}
html.webshop--ishomepage .main--margintop {
  margin-top: 0 !important;
  width: 100%;
}
html.webshop--ishomepage .product_listing {
  padding-top: 30px;
  padding-bottom: 30px;
  margin-bottom: 0;
  max-width: 1800px;
  margin: 0 auto;
}
@media (max-width: 576px) {
  html.webshop--ishomepage .product_listing {
    margin: 0;
  }
  html.webshop--ishomepage .product_listing .col-lg-4 {
    width: 100%;
  }
}
@media only screen and (min-width: 577px) and (max-width: 768px) {
  html.webshop--ishomepage .product_listing .col-lg-4 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media (min-width: 992px) {
  html.webshop--ishomepage .product_listing .col-lg-4 {
    flex: 0 0 25%;
    max-width: 25%;
  }
}
@media (min-width: 1200px) {
  html.webshop--ishomepage .product_listing .col-lg-4 {
    flex: 0 0 25%;
    max-width: 25%;
  }
}
@media (min-width: 1400px) {
  html.webshop--ishomepage .product_listing .col-lg-4 {
    flex: 0 0 25%;
    max-width: 25%;
  }
}