@charset "UTF-8";
@import url("https://use.typekit.net/whe0xte.css");
@import url("https://fonts.googleapis.com/css?family=Muli:300,400,700&display=swap");
html.webshop--ischeckoutpage .site-wrapper,
html.webshop--ischeckoutdonepage .site-wrapper,
html.webshop--isconfirmquotepage .site-wrapper {
  background-color: #f5f8fb !important;
}
html.webshop--ischeckoutpage .site-wrapper .card,
html.webshop--ischeckoutdonepage .site-wrapper .card,
html.webshop--isconfirmquotepage .site-wrapper .card {
  border: 1px solid #ccc !important;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}
@media (max-width: 450px) {
  html.webshop--ischeckoutpage .site-wrapper .card,
  html.webshop--ischeckoutdonepage .site-wrapper .card,
  html.webshop--isconfirmquotepage .site-wrapper .card {
    font-size: 13px;
    line-height: 19px;
  }
}

html.webshop--isconfirmquotepage .webshop-checkout__ordertotal {
  white-space: nowrap;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 20px;
}
html.webshop--isconfirmquotepage .webshop-checkout__ordertotal:before {
  content: "€";
  display: inline-block;
  padding-right: 2px;
}
html.webshop--isconfirmquotepage .wh-form__fieldgroup .wh-form__label[for="confirmquoteform-paymentmethod.paymentmethod"],
html.webshop--isconfirmquotepage .wh-form__fieldgroup .wh-form__label[for=confirmquoteform-agree_terms] {
  display: none;
}

.webshop-listcart__options {
  font-size: 12px;
  line-height: 18px;
}

.webshop-checkout__form:not(.webshop-checkout--offerloyaltypoints) .webshop-checkout__loyaltysection {
  display: none !important;
}

/* The part of the form controlled by the alternative interaction needs to be hidden */
.hp-shippingmethod__formpart {
  display: none;
}

/* All the stuff that needs to be hidden when address is not NL */
.hp-shippingmethod__nlspecific--hidden {
  display: none;
}

/* disable amount in checkout */
.webshop-listcart__inputamount {
  pointer-events: none;
  background-color: -internal-light-dark(rgba(239, 239, 239, 0.3), rgba(59, 59, 59, 0.3));
  border-color: rgba(118, 118, 118, 0.3);
}

.hp-checkout__addcode {
  height: 0;
  display: flex;
  overflow: hidden;
  transition: height 300ms;
}

input.webshop-checkout__addcouponcode {
  border: 1px solid #ccc;
  border-radius: 0;
  box-shadow: none;
  height: 30px;
  font: inherit;
  padding: 5px 10px;
  max-width: 300px;
  -webkit-appearance: none;
}

.webshop-checkout__addcouponbutton {
  border-radius: 0 3px 3px 0;
}

.webshop-checkout__shippingmethod.webshop-checkout--isfree .webshop-checkout__cost {
  display: none;
}

.webshop-checkout__shippingmethod.webshop-checkout--isfree .webshop-checkout__costs:after {
  content: "gratis";
  color: #8fc13e;
  font-weight: 600;
  font-size: 14px;
}

.webshop-checkout__paymentmethod.webshop-checkout--isfree .webshop-checkout__costs {
  display: none;
}

.wh-form__label[for=orderform-wrd_lastname],
.wh-form__label[for="orderform-billing_address.nr_detail"],
.wh-form__label[for="orderform-billing_address.city"],
.wh-form__label[for="orderform-shipping_address.nr_detail"],
.wh-form__label[for="orderform-shipping_address.city"] {
  visibility: hidden;
  width: 0;
}

.wh-form__label[for="orderform-billing_address.zip"],
.wh-form__label[for="orderform-shipping_address.zip"] {
  white-space: nowrap;
}

html.wh-wrdauth--isloggedin .hp-checkout__loginline {
  display: none;
}

html:not(.hp--showcompanyfields) .hp-checkout__inlinecompanyfields {
  display: none;
}

.webshop-checkout__paymentmethods .wh-form__fieldline .wh-form__subfield {
  margin-left: 50px;
}

.webshop-checkout__paymentmethods .wh-form__fieldline .wh-form__subfield {
  position: relative;
  top: -33px;
}

#orderform-shipping_country {
  width: 155px;
  padding: 3px 5px;
  -webkit-appearance: menulist;
}

div[data-wh-form-group-for=shipping_country],
div[data-wh-form-group-for=shipping_zip] {
  display: flex !important;
  -ms-flex-direction: inherit;
  -webkit-flex-direction: inherit;
  flex-direction: inherit;
  position: relative;
  height: 30px;
}
div[data-wh-form-group-for=shipping_country] .wh-form__label,
div[data-wh-form-group-for=shipping_zip] .wh-form__label {
  min-width: 120px;
}