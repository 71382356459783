@import url("https://use.typekit.net/whe0xte.css");
@import url("https://fonts.googleapis.com/css?family=Muli:300,400,700&display=swap");
footer {
  max-width: 1920px;
  margin: 0 auto;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  background-color: #fff;
}
footer #ondermenu {
  padding: 50px 0;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
footer #ondermenu .row--cols > div {
  margin-bottom: 20px;
  font-size: 14px;
}
footer #ondermenu .footer__widget a {
  color: #444444 !important;
}
footer #ondermenu .footer__widget ul {
  display: table;
  list-style: none;
  padding: 0;
  margin: 0 -7.5px 0 -7.5px;
}
footer #ondermenu .footer__widget li {
  display: table-row;
}
footer #ondermenu .footer__widget li a {
  color: #444444 !important;
}
footer #ondermenu .footer__widget li:before {
  content: "\f105";
  width: 1.5em;
  font-size: 16px;
  line-height: 28px;
  font-family: FontAwesome;
  display: table-cell;
  padding-left: 7px;
  color: #005bbe !important;
}
footer #ondermenu .footer__widget .footer__social {
  margin: 20px 0 0;
}
footer #ondermenu .footer__widget .footer__social i {
  font-size: 28px;
  margin-right: 10px;
  color: #005bbe !important;
}
footer #ondermenu .footer__widget .blogitem .card-title a {
  color: #005bbe !important;
}
footer .bottom__logos {
  padding: 5px 0 40px 0;
  border-bottom: 1px solid #ccc;
  position: relative;
}
footer .bottom__logos .payment__logos {
  display: inline;
}
footer .bottom__logos .payment__logos img {
  max-height: 35px;
  width: auto;
  max-width: 50px;
  margin-right: 20px;
}
@media (max-width: 567px) {
  footer .bottom__logos .payment__logos img {
    max-height: 20px;
    max-width: 49px;
    margin-right: 5px;
  }
}
footer .bottom__line img {
  position: relative;
  top: -4px;
}
footer .bottomnav ul {
  list-style-type: none;
  margin: 0;
  padding: 0 15px;
}
footer .bottomnav ul li {
  display: inline-block;
}
footer .bottomnav ul li a {
  color: #58595b;
}
footer .bottomnav ul li + li:before {
  content: " - ";
  padding: 0 6px;
  display: inline-block;
}
footer h5 {
  display: block;
  margin: 0 0 10px;
  font-size: 21px;
}