@import url("https://use.typekit.net/whe0xte.css");
@import url("https://fonts.googleapis.com/css?family=Muli:300,400,700&display=swap");
html.webshop--isdecoratie .slick-slide {
  opacity: 1 !important;
}
html.webshop--isdecoratie .product__images__preview__container {
  display: none;
}
@media (max-width: 767px) {
  html.webshop--isdecoratie .product__images {
    padding-top: 86%;
    position: relative;
    margin-top: 0;
    margin-bottom: 0;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  html.webshop--isdecoratie .product__images {
    padding-top: 58%;
    position: relative;
  }
}
@media (min-width: 992px) {
  html.webshop--isdecoratie .product__images {
    position: sticky;
    top: 90px;
    min-height: 428px;
  }
}
@media (min-width: 320px) and (max-width: 420px) {
  html.webshop--isdecoratie .product__options h2 {
    font-size: 21px;
  }
}
html.webshop--isdecoratie .product__options .dd-select:not(.dd-open) + .dd-options {
  display: none !important;
}
html.webshop--isdecoratie .product__options .option__kleur .dd-container .dd-options li {
  display: flex;
  order: 2;
}
html.webshop--isdecoratie .product__options .option__kleur .dd-container .dd-options li:nth-child(1) {
  order: 1;
}
html.webshop--isdecoratie .product__options .option__kleur .dd-container .dd-options li:nth-child(2) {
  order: 0;
}
html.webshop--isdecoratie .product__options .option__kleur .dd-container .dd-options li.option__label {
  display: flex;
  margin-bottom: 5px;
  width: 100%;
}
html.webshop--isdecoratie .product__options .option__kleur .dd-container .dd-options li.option__label .dd-option {
  box-shadow: none;
  border: none;
  pointer-events: none;
  cursor: default;
  margin: 0;
}
html.webshop--isdecoratie .product__options .option__kleur .dd-container .dd-options li.option__label .dd-option .dd-option-text {
  display: block;
  margin-bottom: 0;
  line-height: normal !important;
  margin-top: 5px;
}
html.webshop--isdecoratie .product__options .option__kleur-1,
html.webshop--isdecoratie .product__options .option__kleur-2,
html.webshop--isdecoratie .product__options .option__kleur-3,
html.webshop--isdecoratie .product__options .option__kleur-4,
html.webshop--isdecoratie .product__options .option__kleur-5,
html.webshop--isdecoratie .product__options .option__kleur-6,
html.webshop--isdecoratie .product__options .option__kleur-7 {
  float: left;
}
html.webshop--isdecoratie .product__options .option__kleur-1 .dd-container .dd-options li,
html.webshop--isdecoratie .product__options .option__kleur-2 .dd-container .dd-options li,
html.webshop--isdecoratie .product__options .option__kleur-3 .dd-container .dd-options li,
html.webshop--isdecoratie .product__options .option__kleur-4 .dd-container .dd-options li,
html.webshop--isdecoratie .product__options .option__kleur-5 .dd-container .dd-options li,
html.webshop--isdecoratie .product__options .option__kleur-6 .dd-container .dd-options li,
html.webshop--isdecoratie .product__options .option__kleur-7 .dd-container .dd-options li {
  display: flex;
  order: 2;
}
html.webshop--isdecoratie .product__options .option__kleur-1 .dd-container .dd-options li:nth-child(1),
html.webshop--isdecoratie .product__options .option__kleur-2 .dd-container .dd-options li:nth-child(1),
html.webshop--isdecoratie .product__options .option__kleur-3 .dd-container .dd-options li:nth-child(1),
html.webshop--isdecoratie .product__options .option__kleur-4 .dd-container .dd-options li:nth-child(1),
html.webshop--isdecoratie .product__options .option__kleur-5 .dd-container .dd-options li:nth-child(1),
html.webshop--isdecoratie .product__options .option__kleur-6 .dd-container .dd-options li:nth-child(1),
html.webshop--isdecoratie .product__options .option__kleur-7 .dd-container .dd-options li:nth-child(1) {
  order: 1;
}
html.webshop--isdecoratie .product__options .option__kleur-1 .dd-container .dd-options li:nth-child(2),
html.webshop--isdecoratie .product__options .option__kleur-2 .dd-container .dd-options li:nth-child(2),
html.webshop--isdecoratie .product__options .option__kleur-3 .dd-container .dd-options li:nth-child(2),
html.webshop--isdecoratie .product__options .option__kleur-4 .dd-container .dd-options li:nth-child(2),
html.webshop--isdecoratie .product__options .option__kleur-5 .dd-container .dd-options li:nth-child(2),
html.webshop--isdecoratie .product__options .option__kleur-6 .dd-container .dd-options li:nth-child(2),
html.webshop--isdecoratie .product__options .option__kleur-7 .dd-container .dd-options li:nth-child(2) {
  order: 0;
}
html.webshop--isdecoratie .product__options .option__kleur-1 .dd-container .dd-options li.option__label,
html.webshop--isdecoratie .product__options .option__kleur-2 .dd-container .dd-options li.option__label,
html.webshop--isdecoratie .product__options .option__kleur-3 .dd-container .dd-options li.option__label,
html.webshop--isdecoratie .product__options .option__kleur-4 .dd-container .dd-options li.option__label,
html.webshop--isdecoratie .product__options .option__kleur-5 .dd-container .dd-options li.option__label,
html.webshop--isdecoratie .product__options .option__kleur-6 .dd-container .dd-options li.option__label,
html.webshop--isdecoratie .product__options .option__kleur-7 .dd-container .dd-options li.option__label {
  display: flex;
  margin-bottom: 5px;
  width: 100%;
}
html.webshop--isdecoratie .product__options .option__kleur-1 .dd-container .dd-options li.option__label .dd-option,
html.webshop--isdecoratie .product__options .option__kleur-2 .dd-container .dd-options li.option__label .dd-option,
html.webshop--isdecoratie .product__options .option__kleur-3 .dd-container .dd-options li.option__label .dd-option,
html.webshop--isdecoratie .product__options .option__kleur-4 .dd-container .dd-options li.option__label .dd-option,
html.webshop--isdecoratie .product__options .option__kleur-5 .dd-container .dd-options li.option__label .dd-option,
html.webshop--isdecoratie .product__options .option__kleur-6 .dd-container .dd-options li.option__label .dd-option,
html.webshop--isdecoratie .product__options .option__kleur-7 .dd-container .dd-options li.option__label .dd-option {
  box-shadow: none;
  border: none;
  pointer-events: none;
  cursor: default;
  margin: 0;
}
html.webshop--isdecoratie .product__options .option__kleur-1 .dd-container .dd-options li.option__label .dd-option .dd-option-text,
html.webshop--isdecoratie .product__options .option__kleur-2 .dd-container .dd-options li.option__label .dd-option .dd-option-text,
html.webshop--isdecoratie .product__options .option__kleur-3 .dd-container .dd-options li.option__label .dd-option .dd-option-text,
html.webshop--isdecoratie .product__options .option__kleur-4 .dd-container .dd-options li.option__label .dd-option .dd-option-text,
html.webshop--isdecoratie .product__options .option__kleur-5 .dd-container .dd-options li.option__label .dd-option .dd-option-text,
html.webshop--isdecoratie .product__options .option__kleur-6 .dd-container .dd-options li.option__label .dd-option .dd-option-text,
html.webshop--isdecoratie .product__options .option__kleur-7 .dd-container .dd-options li.option__label .dd-option .dd-option-text {
  display: block;
  margin-bottom: 0;
  line-height: normal !important;
  margin-top: 5px;
}
@media (min-width: 320px) and (max-width: 420px) {
  html.webshop--isdecoratie .product__options .option__kleur-2 .dd-container .dd-options {
    left: -67px;
  }
}
@media (min-width: 320px) and (max-width: 420px) {
  html.webshop--isdecoratie .product__options .option__kleur-3 .dd-container .dd-options {
    left: -123px;
  }
}
html.webshop--isdecoratie .product__options .option__kleur-4 {
  margin-right: 0;
}
html.webshop--isdecoratie .product__options .option__kleur-4 .dd-container .dd-options {
  left: -180px;
}
html.webshop--isdecoratie .product__options .option__kleur-topballon .dd-container .dd-options li,
html.webshop--isdecoratie .product__options .option__kleur-top .dd-container .dd-options li {
  display: flex;
  order: 2;
}
html.webshop--isdecoratie .product__options .option__kleur-topballon .dd-container .dd-options li:nth-child(1),
html.webshop--isdecoratie .product__options .option__kleur-top .dd-container .dd-options li:nth-child(1) {
  order: 1;
}
html.webshop--isdecoratie .product__options .option__kleur-topballon .dd-container .dd-options li:nth-child(2),
html.webshop--isdecoratie .product__options .option__kleur-top .dd-container .dd-options li:nth-child(2) {
  order: 0;
}
html.webshop--isdecoratie .product__options .option__kleur-topballon .dd-container .dd-options li.option__label,
html.webshop--isdecoratie .product__options .option__kleur-top .dd-container .dd-options li.option__label {
  display: flex;
  margin-bottom: 5px;
  width: 100%;
}
html.webshop--isdecoratie .product__options .option__kleur-topballon .dd-container .dd-options li.option__label .dd-option,
html.webshop--isdecoratie .product__options .option__kleur-top .dd-container .dd-options li.option__label .dd-option {
  box-shadow: none;
  border: none;
  pointer-events: none;
  cursor: default;
  margin: 0;
}
html.webshop--isdecoratie .product__options .option__kleur-topballon .dd-container .dd-options li.option__label .dd-option .dd-option-text,
html.webshop--isdecoratie .product__options .option__kleur-top .dd-container .dd-options li.option__label .dd-option .dd-option-text {
  display: block;
  margin-bottom: 0;
  line-height: normal !important;
  margin-top: 5px;
}
html.webshop--isdecoratie .product__options .option__kleur,
html.webshop--isdecoratie .product__options .option__kleur-1,
html.webshop--isdecoratie .product__options .option__kleur-2,
html.webshop--isdecoratie .product__options .option__kleur-3,
html.webshop--isdecoratie .product__options .option__kleur-4,
html.webshop--isdecoratie .product__options .option__kleur-5,
html.webshop--isdecoratie .product__options .option__kleur-6,
html.webshop--isdecoratie .product__options .option__kleur-7,
html.webshop--isdecoratie .product__options .option__kleur-top {
  margin-right: 1%;
  width: 24% !important;
  max-width: 85px;
}
html.webshop--isdecoratie .product__options .option__kleur .dd-container,
html.webshop--isdecoratie .product__options .option__kleur-1 .dd-container,
html.webshop--isdecoratie .product__options .option__kleur-2 .dd-container,
html.webshop--isdecoratie .product__options .option__kleur-3 .dd-container,
html.webshop--isdecoratie .product__options .option__kleur-4 .dd-container,
html.webshop--isdecoratie .product__options .option__kleur-5 .dd-container,
html.webshop--isdecoratie .product__options .option__kleur-6 .dd-container,
html.webshop--isdecoratie .product__options .option__kleur-7 .dd-container,
html.webshop--isdecoratie .product__options .option__kleur-top .dd-container {
  box-shadow: none !important;
}
html.webshop--isdecoratie .product__options .option__kleur .dd-container .dd-options,
html.webshop--isdecoratie .product__options .option__kleur-1 .dd-container .dd-options,
html.webshop--isdecoratie .product__options .option__kleur-2 .dd-container .dd-options,
html.webshop--isdecoratie .product__options .option__kleur-3 .dd-container .dd-options,
html.webshop--isdecoratie .product__options .option__kleur-4 .dd-container .dd-options,
html.webshop--isdecoratie .product__options .option__kleur-5 .dd-container .dd-options,
html.webshop--isdecoratie .product__options .option__kleur-6 .dd-container .dd-options,
html.webshop--isdecoratie .product__options .option__kleur-7 .dd-container .dd-options,
html.webshop--isdecoratie .product__options .option__kleur-top .dd-container .dd-options {
  position: absolute;
  width: 260px !important;
  padding: 10px 0 5px 11px;
  background-color: #fff !important;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  border-top: 1px solid #ccc;
}
html.webshop--isdecoratie .product__options .option__kleur .dd-container .dd-options li .dd-option,
html.webshop--isdecoratie .product__options .option__kleur-1 .dd-container .dd-options li .dd-option,
html.webshop--isdecoratie .product__options .option__kleur-2 .dd-container .dd-options li .dd-option,
html.webshop--isdecoratie .product__options .option__kleur-3 .dd-container .dd-options li .dd-option,
html.webshop--isdecoratie .product__options .option__kleur-4 .dd-container .dd-options li .dd-option,
html.webshop--isdecoratie .product__options .option__kleur-5 .dd-container .dd-options li .dd-option,
html.webshop--isdecoratie .product__options .option__kleur-6 .dd-container .dd-options li .dd-option,
html.webshop--isdecoratie .product__options .option__kleur-7 .dd-container .dd-options li .dd-option,
html.webshop--isdecoratie .product__options .option__kleur-top .dd-container .dd-options li .dd-option {
  display: block;
  padding: 0;
  border: 2px solid transparent;
  margin: 0 6px 4px 0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}
html.webshop--isdecoratie .product__options .option__kleur .dd-container .dd-options li .dd-option.dd-option-selected,
html.webshop--isdecoratie .product__options .option__kleur-1 .dd-container .dd-options li .dd-option.dd-option-selected,
html.webshop--isdecoratie .product__options .option__kleur-2 .dd-container .dd-options li .dd-option.dd-option-selected,
html.webshop--isdecoratie .product__options .option__kleur-3 .dd-container .dd-options li .dd-option.dd-option-selected,
html.webshop--isdecoratie .product__options .option__kleur-4 .dd-container .dd-options li .dd-option.dd-option-selected,
html.webshop--isdecoratie .product__options .option__kleur-5 .dd-container .dd-options li .dd-option.dd-option-selected,
html.webshop--isdecoratie .product__options .option__kleur-6 .dd-container .dd-options li .dd-option.dd-option-selected,
html.webshop--isdecoratie .product__options .option__kleur-7 .dd-container .dd-options li .dd-option.dd-option-selected,
html.webshop--isdecoratie .product__options .option__kleur-top .dd-container .dd-options li .dd-option.dd-option-selected {
  border: 2px solid #75b325;
}
html.webshop--isdecoratie .product__options .option__kleur .dd-container .dd-options li .dd-option .dd-option-image,
html.webshop--isdecoratie .product__options .option__kleur-1 .dd-container .dd-options li .dd-option .dd-option-image,
html.webshop--isdecoratie .product__options .option__kleur-2 .dd-container .dd-options li .dd-option .dd-option-image,
html.webshop--isdecoratie .product__options .option__kleur-3 .dd-container .dd-options li .dd-option .dd-option-image,
html.webshop--isdecoratie .product__options .option__kleur-4 .dd-container .dd-options li .dd-option .dd-option-image,
html.webshop--isdecoratie .product__options .option__kleur-5 .dd-container .dd-options li .dd-option .dd-option-image,
html.webshop--isdecoratie .product__options .option__kleur-6 .dd-container .dd-options li .dd-option .dd-option-image,
html.webshop--isdecoratie .product__options .option__kleur-7 .dd-container .dd-options li .dd-option .dd-option-image,
html.webshop--isdecoratie .product__options .option__kleur-top .dd-container .dd-options li .dd-option .dd-option-image {
  float: none;
  max-width: 30px;
  width: 100%;
  margin-right: 0;
  background-color: #fff !important;
}
html.webshop--isdecoratie .product__options .option__kleur .dd-container .dd-options li .dd-option .dd-option-text,
html.webshop--isdecoratie .product__options .option__kleur-1 .dd-container .dd-options li .dd-option .dd-option-text,
html.webshop--isdecoratie .product__options .option__kleur-2 .dd-container .dd-options li .dd-option .dd-option-text,
html.webshop--isdecoratie .product__options .option__kleur-3 .dd-container .dd-options li .dd-option .dd-option-text,
html.webshop--isdecoratie .product__options .option__kleur-4 .dd-container .dd-options li .dd-option .dd-option-text,
html.webshop--isdecoratie .product__options .option__kleur-5 .dd-container .dd-options li .dd-option .dd-option-text,
html.webshop--isdecoratie .product__options .option__kleur-6 .dd-container .dd-options li .dd-option .dd-option-text,
html.webshop--isdecoratie .product__options .option__kleur-7 .dd-container .dd-options li .dd-option .dd-option-text,
html.webshop--isdecoratie .product__options .option__kleur-top .dd-container .dd-options li .dd-option .dd-option-text {
  display: none;
}
@media (max-width: 767px) {
  html.webshop--isdecoratie .product__options .option__kleur .dd-container .dd-options,
  html.webshop--isdecoratie .product__options .option__kleur-1 .dd-container .dd-options,
  html.webshop--isdecoratie .product__options .option__kleur-2 .dd-container .dd-options,
  html.webshop--isdecoratie .product__options .option__kleur-3 .dd-container .dd-options,
  html.webshop--isdecoratie .product__options .option__kleur-4 .dd-container .dd-options,
  html.webshop--isdecoratie .product__options .option__kleur-5 .dd-container .dd-options,
  html.webshop--isdecoratie .product__options .option__kleur-6 .dd-container .dd-options,
  html.webshop--isdecoratie .product__options .option__kleur-7 .dd-container .dd-options,
  html.webshop--isdecoratie .product__options .option__kleur-top .dd-container .dd-options {
    z-index: 10;
    bottom: 75px;
  }
}
html.webshop--isdecoratie .product__options .option__kleur .dd-container .dd-select,
html.webshop--isdecoratie .product__options .option__kleur-1 .dd-container .dd-select,
html.webshop--isdecoratie .product__options .option__kleur-2 .dd-container .dd-select,
html.webshop--isdecoratie .product__options .option__kleur-3 .dd-container .dd-select,
html.webshop--isdecoratie .product__options .option__kleur-4 .dd-container .dd-select,
html.webshop--isdecoratie .product__options .option__kleur-5 .dd-container .dd-select,
html.webshop--isdecoratie .product__options .option__kleur-6 .dd-container .dd-select,
html.webshop--isdecoratie .product__options .option__kleur-7 .dd-container .dd-select,
html.webshop--isdecoratie .product__options .option__kleur-top .dd-container .dd-select {
  cursor: pointer;
  width: 95% !important;
  max-width: 80px;
  text-align: center;
  -webkit-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  -moz-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
@media (min-width: 320px) and (max-width: 400px) {
  html.webshop--isdecoratie .product__options .option__kleur .dd-container .dd-select,
  html.webshop--isdecoratie .product__options .option__kleur-1 .dd-container .dd-select,
  html.webshop--isdecoratie .product__options .option__kleur-2 .dd-container .dd-select,
  html.webshop--isdecoratie .product__options .option__kleur-3 .dd-container .dd-select,
  html.webshop--isdecoratie .product__options .option__kleur-4 .dd-container .dd-select,
  html.webshop--isdecoratie .product__options .option__kleur-5 .dd-container .dd-select,
  html.webshop--isdecoratie .product__options .option__kleur-6 .dd-container .dd-select,
  html.webshop--isdecoratie .product__options .option__kleur-7 .dd-container .dd-select,
  html.webshop--isdecoratie .product__options .option__kleur-top .dd-container .dd-select {
    height: 90px;
  }
}
@media (min-width: 320px) and (max-width: 400px) {
  html.webshop--isdecoratie .product__options .option__kleur .dd-container .dd-select .dd-selected,
  html.webshop--isdecoratie .product__options .option__kleur-1 .dd-container .dd-select .dd-selected,
  html.webshop--isdecoratie .product__options .option__kleur-2 .dd-container .dd-select .dd-selected,
  html.webshop--isdecoratie .product__options .option__kleur-3 .dd-container .dd-select .dd-selected,
  html.webshop--isdecoratie .product__options .option__kleur-4 .dd-container .dd-select .dd-selected,
  html.webshop--isdecoratie .product__options .option__kleur-5 .dd-container .dd-select .dd-selected,
  html.webshop--isdecoratie .product__options .option__kleur-6 .dd-container .dd-select .dd-selected,
  html.webshop--isdecoratie .product__options .option__kleur-7 .dd-container .dd-select .dd-selected,
  html.webshop--isdecoratie .product__options .option__kleur-top .dd-container .dd-select .dd-selected {
    padding: 3px;
  }
}
html.webshop--isdecoratie .product__options .option__kleur .dd-container .dd-select .dd-selected .dd-selected-image,
html.webshop--isdecoratie .product__options .option__kleur-1 .dd-container .dd-select .dd-selected .dd-selected-image,
html.webshop--isdecoratie .product__options .option__kleur-2 .dd-container .dd-select .dd-selected .dd-selected-image,
html.webshop--isdecoratie .product__options .option__kleur-3 .dd-container .dd-select .dd-selected .dd-selected-image,
html.webshop--isdecoratie .product__options .option__kleur-4 .dd-container .dd-select .dd-selected .dd-selected-image,
html.webshop--isdecoratie .product__options .option__kleur-5 .dd-container .dd-select .dd-selected .dd-selected-image,
html.webshop--isdecoratie .product__options .option__kleur-6 .dd-container .dd-select .dd-selected .dd-selected-image,
html.webshop--isdecoratie .product__options .option__kleur-7 .dd-container .dd-select .dd-selected .dd-selected-image,
html.webshop--isdecoratie .product__options .option__kleur-top .dd-container .dd-select .dd-selected .dd-selected-image {
  float: none !important;
}
html.webshop--isdecoratie .product__options .option__kleur .dd-container .dd-select .dd-selected .dd-selected-text,
html.webshop--isdecoratie .product__options .option__kleur-1 .dd-container .dd-select .dd-selected .dd-selected-text,
html.webshop--isdecoratie .product__options .option__kleur-2 .dd-container .dd-select .dd-selected .dd-selected-text,
html.webshop--isdecoratie .product__options .option__kleur-3 .dd-container .dd-select .dd-selected .dd-selected-text,
html.webshop--isdecoratie .product__options .option__kleur-4 .dd-container .dd-select .dd-selected .dd-selected-text,
html.webshop--isdecoratie .product__options .option__kleur-5 .dd-container .dd-select .dd-selected .dd-selected-text,
html.webshop--isdecoratie .product__options .option__kleur-6 .dd-container .dd-select .dd-selected .dd-selected-text,
html.webshop--isdecoratie .product__options .option__kleur-7 .dd-container .dd-select .dd-selected .dd-selected-text,
html.webshop--isdecoratie .product__options .option__kleur-top .dd-container .dd-select .dd-selected .dd-selected-text {
  line-height: 12px !important;
  font-size: 12px;
  margin-top: 5px;
  cursor: pointer;
  display: block;
}
@media (min-width: 320px) and (max-width: 400px) {
  html.webshop--isdecoratie .product__options .option__kleur .dd-container .dd-select .dd-selected .dd-selected-text,
  html.webshop--isdecoratie .product__options .option__kleur-1 .dd-container .dd-select .dd-selected .dd-selected-text,
  html.webshop--isdecoratie .product__options .option__kleur-2 .dd-container .dd-select .dd-selected .dd-selected-text,
  html.webshop--isdecoratie .product__options .option__kleur-3 .dd-container .dd-select .dd-selected .dd-selected-text,
  html.webshop--isdecoratie .product__options .option__kleur-4 .dd-container .dd-select .dd-selected .dd-selected-text,
  html.webshop--isdecoratie .product__options .option__kleur-5 .dd-container .dd-select .dd-selected .dd-selected-text,
  html.webshop--isdecoratie .product__options .option__kleur-6 .dd-container .dd-select .dd-selected .dd-selected-text,
  html.webshop--isdecoratie .product__options .option__kleur-7 .dd-container .dd-select .dd-selected .dd-selected-text,
  html.webshop--isdecoratie .product__options .option__kleur-top .dd-container .dd-select .dd-selected .dd-selected-text {
    line-height: 14px !important;
    font-size: 10px;
    word-break: break-word;
  }
}
html.webshop--isdecoratie .product__options .alert {
  line-height: 18px;
  padding: 10px;
}
html.webshop--isdecoratie .product__options .printtypebox .option__opdrukkleur .dd-container .dd-options,
html.webshop--isdecoratie .product__options .printtypebox .option__kleur-gewicht .dd-container .dd-options,
html.webshop--isdecoratie .product__options .extra__options .option__opdrukkleur .dd-container .dd-options,
html.webshop--isdecoratie .product__options .extra__options .option__kleur-gewicht .dd-container .dd-options {
  position: inherit;
  padding: 10px 0 5px 11px;
  background-color: #fff !important;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
html.webshop--isdecoratie .product__options .printtypebox .option__opdrukkleur .dd-container .dd-options li,
html.webshop--isdecoratie .product__options .printtypebox .option__kleur-gewicht .dd-container .dd-options li,
html.webshop--isdecoratie .product__options .extra__options .option__opdrukkleur .dd-container .dd-options li,
html.webshop--isdecoratie .product__options .extra__options .option__kleur-gewicht .dd-container .dd-options li {
  display: flex;
}
html.webshop--isdecoratie .product__options .printtypebox .option__opdrukkleur .dd-container .dd-options li .dd-option,
html.webshop--isdecoratie .product__options .printtypebox .option__kleur-gewicht .dd-container .dd-options li .dd-option,
html.webshop--isdecoratie .product__options .extra__options .option__opdrukkleur .dd-container .dd-options li .dd-option,
html.webshop--isdecoratie .product__options .extra__options .option__kleur-gewicht .dd-container .dd-options li .dd-option {
  display: block;
  padding: 0;
  border: 2px solid transparent;
  margin: 0 8px 8px 0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}
html.webshop--isdecoratie .product__options .printtypebox .option__opdrukkleur .dd-container .dd-options li .dd-option.dd-option-selected,
html.webshop--isdecoratie .product__options .printtypebox .option__kleur-gewicht .dd-container .dd-options li .dd-option.dd-option-selected,
html.webshop--isdecoratie .product__options .extra__options .option__opdrukkleur .dd-container .dd-options li .dd-option.dd-option-selected,
html.webshop--isdecoratie .product__options .extra__options .option__kleur-gewicht .dd-container .dd-options li .dd-option.dd-option-selected {
  border: 2px solid #75b325;
}
html.webshop--isdecoratie .product__options .printtypebox .option__opdrukkleur .dd-container .dd-options li .dd-option .dd-option-image,
html.webshop--isdecoratie .product__options .printtypebox .option__kleur-gewicht .dd-container .dd-options li .dd-option .dd-option-image,
html.webshop--isdecoratie .product__options .extra__options .option__opdrukkleur .dd-container .dd-options li .dd-option .dd-option-image,
html.webshop--isdecoratie .product__options .extra__options .option__kleur-gewicht .dd-container .dd-options li .dd-option .dd-option-image {
  float: none;
  max-width: 30px;
  width: 100%;
  margin-right: 0;
  background-color: #fff !important;
}
html.webshop--isdecoratie .product__options .printtypebox .option__opdrukkleur .dd-container .dd-options li .dd-option .dd-option-text,
html.webshop--isdecoratie .product__options .printtypebox .option__kleur-gewicht .dd-container .dd-options li .dd-option .dd-option-text,
html.webshop--isdecoratie .product__options .extra__options .option__opdrukkleur .dd-container .dd-options li .dd-option .dd-option-text,
html.webshop--isdecoratie .product__options .extra__options .option__kleur-gewicht .dd-container .dd-options li .dd-option .dd-option-text {
  display: none;
}
html.webshop--isdecoratie #bs__decoration .decoratie__preview div img {
  position: absolute;
  top: 0;
}
html.webshop--isdecoratie .webshop-product__option--hidden {
  display: none;
}

html.ballonnen-cijfers .product__images .product__images__preview__container {
  display: block;
}
@media (max-width: 767px) {
  html.ballonnen-cijfers .product__images {
    padding-top: 0;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  html.ballonnen-cijfers .product__images {
    padding-top: 0;
  }
}
html.ballonnen-cijfers #bs__decoration {
  display: none;
}
html.ballonnen-cijfers .slick-slide {
  opacity: 0 !important;
}
html.ballonnen-cijfers .slick-slide.slick-active {
  opacity: 1 !important;
}
html.ballonnen-cijfers .product__options .option__hoogte {
  float: none;
  width: auto;
}
html.ballonnen-cijfers .product__options .option__hoogte .dd-container {
  height: 35px;
  position: relative;
  box-shadow: none !important;
}
html.ballonnen-cijfers .product__options .option__hoogte .dd-container .dd-select {
  display: none;
}
html.ballonnen-cijfers .product__options .option__hoogte .dd-container .dd-options {
  display: block !important;
  background-color: transparent !important;
  box-shadow: none;
  border: none;
  padding: 0;
}
html.ballonnen-cijfers .product__options .option__hoogte .dd-container .dd-options li {
  display: inline-block;
}
html.ballonnen-cijfers .product__options .option__hoogte .dd-container .dd-options li .dd-option {
  display: block;
  width: 60px;
  height: 30px;
  padding: 0 8px;
  border: 1px solid #ccc;
  margin: 0 10px 10px 0;
  -webkit-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  -moz-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
html.ballonnen-cijfers .product__options .option__hoogte .dd-container .dd-options li .dd-option.dd-option-selected {
  border: 2px solid #75b325;
}
html.ballonnen-cijfers .product__options .option__hoogte .dd-container .dd-options li .dd-option .dd-option-text {
  float: none;
  width: 100%;
  margin-right: 0;
  margin-bottom: 0;
  display: block;
  height: 100%;
  text-align: center;
  line-height: 28px;
  cursor: pointer;
  font-size: 13px;
}
html.ballonnen-cijfers .product__options .option__cijfer .dd-container {
  position: relative;
  box-shadow: none !important;
}
html.ballonnen-cijfers .product__options .option__cijfer .dd-container .dd-select {
  display: none;
}
html.ballonnen-cijfers .product__options .option__cijfer .dd-container .dd-options {
  display: block !important;
  background-color: transparent !important;
  box-shadow: none;
  border: none;
  position: initial;
}
html.ballonnen-cijfers .product__options .option__cijfer .dd-container .dd-options li {
  display: inline-block;
}
html.ballonnen-cijfers .product__options .option__cijfer .dd-container .dd-options li .dd-option {
  display: block;
  padding: 0;
  width: 30px;
  height: 30px;
  border: 1px solid #ccc;
  margin: 0 10px 0 0;
  -webkit-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  -moz-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
html.ballonnen-cijfers .product__options .option__cijfer .dd-container .dd-options li .dd-option.dd-option-selected {
  border: 2px solid #75b325;
}
html.ballonnen-cijfers .product__options .option__cijfer .dd-container .dd-options li .dd-option .dd-option-text {
  float: none;
  width: 100%;
  margin-right: 0;
  margin-bottom: 0;
  display: block;
  height: 100%;
  text-align: center;
  line-height: 28px;
  cursor: pointer;
  font-size: 13px;
}

html.ballonnen-pilaar .product__images {
  background: url(../../web/img/maat-pilaar.png) no-repeat left top;
}

html.luxe-ballonnen-pilaar .product__images {
  background: url(../../web/img/maat-luxepilaar.png) no-repeat left top;
}

html.helium-tros-3-ballonnen .product__images__preview__container,
html.helium-tros-met-opdruk-3-ballonnen .product__images__preview__container,
html.helium-tros-5-ballonnen .product__images__preview__container,
html.helium-tros-met-opdruk-5-ballonnen .product__images__preview__container {
  display: block;
  position: absolute;
  z-index: 1;
  top: 0;
}
html.helium-tros-3-ballonnen.ddslick--selected .product__images__preview__container,
html.helium-tros-met-opdruk-3-ballonnen.ddslick--selected .product__images__preview__container,
html.helium-tros-5-ballonnen.ddslick--selected .product__images__preview__container,
html.helium-tros-met-opdruk-5-ballonnen.ddslick--selected .product__images__preview__container {
  display: none;
}
html.helium-tros-3-ballonnen .webshop-product__options,
html.helium-tros-met-opdruk-3-ballonnen .webshop-product__options,
html.helium-tros-5-ballonnen .webshop-product__options,
html.helium-tros-met-opdruk-5-ballonnen .webshop-product__options {
  display: flex;
}
html.helium-tros-3-ballonnen .webshop-product__options .option__kleur-4,
html.helium-tros-3-ballonnen .webshop-product__options .option__kleur-5,
html.helium-tros-3-ballonnen .webshop-product__options .option__kleur-4-hidden,
html.helium-tros-3-ballonnen .webshop-product__options .option__kleur-5-hidden,
html.helium-tros-3-ballonnen .webshop-product__options .option__kleur-6,
html.helium-tros-3-ballonnen .webshop-product__options .option__kleur-7,
html.helium-tros-met-opdruk-3-ballonnen .webshop-product__options .option__kleur-4,
html.helium-tros-met-opdruk-3-ballonnen .webshop-product__options .option__kleur-5,
html.helium-tros-met-opdruk-3-ballonnen .webshop-product__options .option__kleur-4-hidden,
html.helium-tros-met-opdruk-3-ballonnen .webshop-product__options .option__kleur-5-hidden,
html.helium-tros-met-opdruk-3-ballonnen .webshop-product__options .option__kleur-6,
html.helium-tros-met-opdruk-3-ballonnen .webshop-product__options .option__kleur-7,
html.helium-tros-5-ballonnen .webshop-product__options .option__kleur-4,
html.helium-tros-5-ballonnen .webshop-product__options .option__kleur-5,
html.helium-tros-5-ballonnen .webshop-product__options .option__kleur-4-hidden,
html.helium-tros-5-ballonnen .webshop-product__options .option__kleur-5-hidden,
html.helium-tros-5-ballonnen .webshop-product__options .option__kleur-6,
html.helium-tros-5-ballonnen .webshop-product__options .option__kleur-7,
html.helium-tros-met-opdruk-5-ballonnen .webshop-product__options .option__kleur-4,
html.helium-tros-met-opdruk-5-ballonnen .webshop-product__options .option__kleur-5,
html.helium-tros-met-opdruk-5-ballonnen .webshop-product__options .option__kleur-4-hidden,
html.helium-tros-met-opdruk-5-ballonnen .webshop-product__options .option__kleur-5-hidden,
html.helium-tros-met-opdruk-5-ballonnen .webshop-product__options .option__kleur-6,
html.helium-tros-met-opdruk-5-ballonnen .webshop-product__options .option__kleur-7 {
  display: none;
}
html.helium-tros-3-ballonnen .webshop-product__options .option__kleur-1,
html.helium-tros-met-opdruk-3-ballonnen .webshop-product__options .option__kleur-1,
html.helium-tros-5-ballonnen .webshop-product__options .option__kleur-1,
html.helium-tros-met-opdruk-5-ballonnen .webshop-product__options .option__kleur-1 {
  order: 1;
}
html.helium-tros-3-ballonnen .webshop-product__options .option__kleur-2,
html.helium-tros-met-opdruk-3-ballonnen .webshop-product__options .option__kleur-2,
html.helium-tros-5-ballonnen .webshop-product__options .option__kleur-2,
html.helium-tros-met-opdruk-5-ballonnen .webshop-product__options .option__kleur-2 {
  order: 2;
}
html.helium-tros-3-ballonnen .webshop-product__options .option__kleur-3,
html.helium-tros-met-opdruk-3-ballonnen .webshop-product__options .option__kleur-3,
html.helium-tros-5-ballonnen .webshop-product__options .option__kleur-3,
html.helium-tros-met-opdruk-5-ballonnen .webshop-product__options .option__kleur-3 {
  order: 3;
}
html.helium-tros-3-ballonnen .webshop-product__options .option__kleur-4,
html.helium-tros-met-opdruk-3-ballonnen .webshop-product__options .option__kleur-4,
html.helium-tros-5-ballonnen .webshop-product__options .option__kleur-4,
html.helium-tros-met-opdruk-5-ballonnen .webshop-product__options .option__kleur-4 {
  order: 4;
}

html.helium-tros-7-ballonnen .product__images__preview__container,
html.helium-tros-met-opdruk-7-ballonnen .product__images__preview__container {
  display: block;
  position: absolute;
  z-index: 1;
  top: 0;
}
html.helium-tros-7-ballonnen.ddslick--selected .product__images__preview__container,
html.helium-tros-met-opdruk-7-ballonnen.ddslick--selected .product__images__preview__container {
  display: none;
}
html.helium-tros-7-ballonnen .webshop-product__options,
html.helium-tros-met-opdruk-7-ballonnen .webshop-product__options {
  display: flex;
}
html.helium-tros-7-ballonnen .webshop-product__options .option__kleur-4-hidden,
html.helium-tros-7-ballonnen .webshop-product__options .option__kleur-5-hidden,
html.helium-tros-7-ballonnen .webshop-product__options .option__kleur-6-hidden,
html.helium-tros-met-opdruk-7-ballonnen .webshop-product__options .option__kleur-4-hidden,
html.helium-tros-met-opdruk-7-ballonnen .webshop-product__options .option__kleur-5-hidden,
html.helium-tros-met-opdruk-7-ballonnen .webshop-product__options .option__kleur-6-hidden {
  display: none;
}
html.helium-tros-7-ballonnen .webshop-product__options .option__kleur-1,
html.helium-tros-met-opdruk-7-ballonnen .webshop-product__options .option__kleur-1 {
  order: 1;
}
html.helium-tros-7-ballonnen .webshop-product__options .option__kleur-2,
html.helium-tros-met-opdruk-7-ballonnen .webshop-product__options .option__kleur-2 {
  order: 2;
}
html.helium-tros-7-ballonnen .webshop-product__options .option__kleur-3,
html.helium-tros-met-opdruk-7-ballonnen .webshop-product__options .option__kleur-3 {
  order: 3;
}
html.helium-tros-7-ballonnen .webshop-product__options .option__kleur-4,
html.helium-tros-met-opdruk-7-ballonnen .webshop-product__options .option__kleur-4 {
  order: 4;
}

html.helium-tros-10-ballonnen .product__images__preview__container,
html.helium-tros-met-opdruk-10-ballonnen .product__images__preview__container {
  display: block;
  position: absolute;
  z-index: 1;
  top: 0;
}
html.helium-tros-10-ballonnen.ddslick--selected .product__images__preview__container,
html.helium-tros-met-opdruk-10-ballonnen.ddslick--selected .product__images__preview__container {
  display: none;
}
html.helium-tros-10-ballonnen .webshop-product__options,
html.helium-tros-met-opdruk-10-ballonnen .webshop-product__options {
  display: flex;
}
html.helium-tros-10-ballonnen .webshop-product__options .option__kleur-5,
html.helium-tros-10-ballonnen .webshop-product__options .option__kleur-6,
html.helium-tros-10-ballonnen .webshop-product__options .option__kleur-7,
html.helium-tros-met-opdruk-10-ballonnen .webshop-product__options .option__kleur-5,
html.helium-tros-met-opdruk-10-ballonnen .webshop-product__options .option__kleur-6,
html.helium-tros-met-opdruk-10-ballonnen .webshop-product__options .option__kleur-7 {
  display: none;
}
html.helium-tros-10-ballonnen .webshop-product__options .option__kleur-1,
html.helium-tros-met-opdruk-10-ballonnen .webshop-product__options .option__kleur-1 {
  order: 1;
}
html.helium-tros-10-ballonnen .webshop-product__options .option__kleur-2,
html.helium-tros-met-opdruk-10-ballonnen .webshop-product__options .option__kleur-2 {
  order: 2;
}
html.helium-tros-10-ballonnen .webshop-product__options .option__kleur-3,
html.helium-tros-met-opdruk-10-ballonnen .webshop-product__options .option__kleur-3 {
  order: 3;
}
html.helium-tros-10-ballonnen .webshop-product__options .option__kleur-4,
html.helium-tros-met-opdruk-10-ballonnen .webshop-product__options .option__kleur-4 {
  order: 4;
}

@media (min-width: 320px) and (max-width: 992px) {
  html.ballonnen-sliert .product__images {
    padding-top: 20%;
  }
}
html.ballonnen-sliert .product__images #bs__decoration .decoratie__preview div img {
  transform: rotate(90deg);
  left: 90px;
}
@media (min-width: 320px) and (max-width: 992px) {
  html.ballonnen-sliert .product__images #bs__decoration .decoratie__preview div img {
    top: -130px;
  }
}
@media (max-width: 567px) {
  html.ballonnen-sliert .product__images #bs__decoration .decoratie__preview div img {
    left: 10%;
  }
}

.option__breedte,
.option__lengte,
.option__hoogte {
  width: 85px;
  float: left;
  margin-right: 20px;
}
@media (max-width: 420px) {
  .option__breedte,
  .option__lengte,
  .option__hoogte {
    width: 100%;
    float: none;
    margin-right: none;
  }
}
.option__breedte .dd-container,
.option__lengte .dd-container,
.option__hoogte .dd-container {
  box-shadow: none !important;
}
.option__breedte .dd-container .dd-options,
.option__lengte .dd-container .dd-options,
.option__hoogte .dd-container .dd-options {
  position: absolute;
  width: 260px !important;
  padding: 10px 0 5px 11px;
  background-color: #fff !important;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  border-top: 1px solid #ccc;
}
.option__breedte .dd-container .dd-options li .dd-option,
.option__lengte .dd-container .dd-options li .dd-option,
.option__hoogte .dd-container .dd-options li .dd-option {
  display: block;
  padding: 0;
  border: 2px solid transparent;
  width: 50px;
  margin: 0 10px 10px 0;
  text-align: center;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}
.option__breedte .dd-container .dd-options li .dd-option.dd-option-selected,
.option__lengte .dd-container .dd-options li .dd-option.dd-option-selected,
.option__hoogte .dd-container .dd-options li .dd-option.dd-option-selected {
  border: 2px solid #75b325;
}
.option__breedte .dd-container .dd-options li .dd-option .dd-option-text,
.option__lengte .dd-container .dd-options li .dd-option .dd-option-text,
.option__hoogte .dd-container .dd-options li .dd-option .dd-option-text {
  margin-bottom: 0;
  font-size: 11px;
  display: block;
  cursor: pointer;
}
.option__breedte .dd-container .dd-select,
.option__lengte .dd-container .dd-select,
.option__hoogte .dd-container .dd-select {
  cursor: pointer;
  width: 95% !important;
  max-width: 80px;
  -webkit-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  -moz-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
@media (max-width: 420px) {
  .option__breedte .dd-container .dd-select,
  .option__lengte .dd-container .dd-select,
  .option__hoogte .dd-container .dd-select {
    max-width: 100%;
    width: 100% !important;
  }
}
@media (min-width: 320px) and (max-width: 400px) {
  .option__breedte .dd-container .dd-select .dd-selected,
  .option__lengte .dd-container .dd-select .dd-selected,
  .option__hoogte .dd-container .dd-select .dd-selected {
    padding: 3px;
  }
}
.option__breedte .dd-container .dd-select .dd-selected .dd-selected-text,
.option__lengte .dd-container .dd-select .dd-selected .dd-selected-text,
.option__hoogte .dd-container .dd-select .dd-selected .dd-selected-text {
  line-height: 16px !important;
  font-size: 12px;
  margin-top: 5px;
  cursor: pointer;
}
@media (min-width: 320px) and (max-width: 400px) {
  .option__breedte .dd-container .dd-select .dd-selected .dd-selected-text,
  .option__lengte .dd-container .dd-select .dd-selected .dd-selected-text,
  .option__hoogte .dd-container .dd-select .dd-selected .dd-selected-text {
    line-height: 14px !important;
    font-size: 10px;
    word-break: break-word;
  }
}

.option__lengte {
  float: none;
}

.option__lintjes {
  display: none;
}

.option__laten-ophangen {
  clear: both;
}