@import url("https://use.typekit.net/whe0xte.css");
@import url("https://fonts.googleapis.com/css?family=Muli:300,400,700&display=swap");
.scroll-down #header,
.scroll-up #header {
  transform: none;
  position: sticky;
  top: 0;
  z-index: 1020;
  background-color: #000;
}
.scroll-down #header #sticky,
.scroll-up #header #sticky {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.scroll-down #header .usps__ballonshop,
.scroll-up #header .usps__ballonshop {
  visibility: hidden;
}
.scroll-down #header .toplevelmenu__container,
.scroll-up #header .toplevelmenu__container {
  margin: 0 auto;
}
.scroll-down #header .dropdown:after,
.scroll-up #header .dropdown:after {
  top: 101px; /* height of header */
}

header {
  background-color: #fff;
  transition: 0.3s;
  z-index: 3;
}
header .navbar {
  padding-top: 0;
  padding-bottom: 0;
}
header .navbar-brand {
  font-size: 14px;
  margin-right: 0;
  padding: 0.2rem 0;
  color: #fff !important;
}
header .navbar-toggler {
  border: none;
  padding-right: 2px;
}
header .topleftpanel .logo {
  display: inline-block;
  transition: 0.2s;
}
header .topleftpanel .logo > img {
  height: auto;
  width: 280px;
}
header .toprightpanel a {
  color: #005bbe !important;
}
header .toprightpanel .list-inline-item {
  position: relative;
}
header .toprightpanel .list-inline-item .dropdown-toggle:after {
  margin-left: 0;
}
header .toprightpanel .list-inline-item .dropdown-menu.show {
  min-width: 100px;
}
header .toprightpanel .cart__balloon {
  background: url("../web/img/icon-balloon-fill.svg");
  width: 21px;
  height: 28px;
  background-size: 17px;
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  top: -9px;
  left: 18px;
}
@media (max-width: 992px) {
  header .logo {
    margin: 10px 0;
  }
  header .logo > img {
    max-width: 230px;
  }
}
@media (max-width: 767px) {
  header .logo {
    margin: 10px 0;
  }
  header .logo > img {
    max-width: 230px;
  }
  header .navbar {
    padding: 0;
  }
}
@media (max-width: 565px) {
  header .container {
    max-width: 100%;
  }
  header .logo > img {
    max-width: 220px;
  }
  header .list-inline-item:first-child {
    margin-right: 0;
  }
  header #mainbar .col-auto {
    padding-left: 5px;
    padding-right: 5px;
  }
}
@media (max-width: 420px) {
  header .logo > img {
    max-width: 190px;
  }
}
header #mainnav {
  width: 100%;
  max-height: 42px;
  /* Dim the screen on hover of menu item and focus on dropdown  */
}
header #mainnav .toplevelmenu__container {
  width: 100%;
}
header #mainnav .toplevelmenu__container .nav-link {
  color: #fff !important;
  padding: 6px 1rem 10px 1rem;
}
header #mainnav .toplevelmenu__container .nav-link i {
  color: #fd8301;
}
header #mainnav .toplevelmenu__container .toplevelmenu {
  margin: 0 auto;
  padding: 0;
  position: relative;
  z-index: 10;
}
@media (min-width: 1200px) {
  header #mainnav .toplevelmenu__container .toplevelmenu__item.dropdown:hover > .dropdown-menu {
    display: block;
  }
}
header #mainnav .toplevelmenu__container .toplevelmenu .dropdown-toggle::after {
  content: "\f107";
  font-family: "FontAwesome";
  font-weight: 900;
  border: 0;
  font-size: 14px;
  position: relative;
  top: 4px;
}
header #mainnav .toplevelmenu__container .toplevelmenu .has-megamenu {
  position: relative;
}
header #mainnav .toplevelmenu__container li {
  display: inline-block;
}
header #mainnav .toplevelmenu__container li a {
  color: #005bbe !important;
}
header #mainnav .toplevelmenu__container li:hover li a:hover {
  color: #005bbe !important;
  text-decoration: underline;
}
header #mainnav .dropdown-menu {
  min-width: 500px;
  width: max-content;
  padding: 2rem;
  margin: -1px 0 0 0;
  background-color: #fff !important;
  min-height: 300px;
}
header #mainnav .dropdown-menu p.normal {
  display: none;
}
header #mainnav .dropdown-menu ul {
  display: block;
  float: left;
  padding-left: 0;
  margin-right: 25px;
  margin-bottom: 25px;
}
header #mainnav .dropdown-menu ul li {
  display: table-row;
  color: #005bbe !important;
  position: relative;
  margin-left: 0;
}
header #mainnav .dropdown-menu ul li a {
  text-decoration: none;
  white-space: nowrap;
  color: #005bbe !important;
}
header #mainnav .dropdown-menu ul li:before {
  content: "\f105";
  width: 1.5em;
  font-size: 16px;
  line-height: 28px;
  font-family: FontAwesome;
  display: inline-block;
  padding-left: 7px;
  color: #005bbe !important;
}
header #mainnav .dropdown-menu ul li:first-child:before {
  display: none;
}
header #mainnav .dropdown-menu ul li strong, header #mainnav .dropdown-menu ul li b {
  font-family: "futura-pt", sans-serif;
  font-weight: 400 !important;
  font-size: 21px;
  color: #3a3e3e !important;
  margin-bottom: 10px;
}
@media all and (min-width: 991px) {
  header #mainnav .dropdown:after {
    content: "";
    top: 102px; /* height of header */
    right: 0;
    bottom: 0;
    left: 0;
    /* transition: opacity .15s ease;*/
    opacity: 0;
    visibility: hidden;
    background-color: rgba(0, 105, 180, 0.1);
    pointer-events: none;
  }
  header #mainnav .dropdown.show .nav-link {
    position: relative;
    z-index: 12;
  }
  header #mainnav .dropdown.show:after {
    z-index: 1;
    opacity: 1;
    visibility: visible;
    position: fixed;
  }
}
@media (min-width: 1200px) {
  header #mainnav .dropdown:hover:after,
  header #mainnav .dropdown:focus:after {
    z-index: 1;
    opacity: 1;
    visibility: visible;
    position: fixed;
  }
}
header .topsearchform,
header .searchform-wrapper {
  white-space: nowrap;
  transition: 0.2s;
  border-radius: 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}
header .topsearchform .input-group > .form-control,
header .searchform-wrapper .input-group > .form-control {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
header .topsearchform .input-group > .input-group-append > .btn,
header .searchform-wrapper .input-group > .input-group-append > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
header .topsearchform .input-group > .input-group-append > .btn img,
header .searchform-wrapper .input-group > .input-group-append > .btn img {
  position: relative;
  top: -2px;
}
header .topsearchform input,
header .searchform-wrapper input {
  border-left: 1px solid #ced4da;
  border-top: 1px solid #ced4da;
  border-right: 1px solid #ced4da;
  border-right: 0;
  outline: none;
  -webkit-appearance: none;
}
header .topsearchform input::placeholder,
header .searchform-wrapper input::placeholder {
  color: #999;
  font-weight: 300;
  font-size: 15px;
}
header .topsearchform button,
header .searchform-wrapper button {
  border-right: 1px solid #ced4da;
  border-top: 1px solid #ced4da;
  border-bottom: 1px solid #ced4da;
  color: #005bbe !important;
}
@media (max-width: 991px) {
  header .topsearchform .input-group > .form-control,
  header .searchform-wrapper .input-group > .form-control {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    border: none;
  }
  header .topsearchform .input-group > .input-group-append > .btn,
  header .searchform-wrapper .input-group > .input-group-append > .btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    background-color: #fff !important;
    color: #005bbe !important;
    border: 0;
  }
}

.usps__ballonshop {
  background-color: #fff !important;
  position: relative;
  border-bottom: 1px solid #ccc;
  z-index: 1;
  height: 41px;
}
.usps__ballonshop a {
  display: flex;
  height: 40px;
  align-items: center;
  font-size: 13px;
  color: #444444;
}
.usps__ballonshop .icon-0 {
  background-image: url("../web/img/icon-shipping.svg");
  width: 37px;
  height: 19px;
  background-size: 30px;
  background-repeat: no-repeat;
  background-position: center center;
  display: inline-block;
}
.usps__ballonshop .icon-1 {
  background-image: url("../web/img/icon-nl.svg");
  width: 30px;
  height: 33px;
  background-size: 25px;
  background-repeat: no-repeat;
  background-position: center center;
  display: inline-block;
}
.usps__ballonshop .icon-2 {
  background-image: url("../web/img/icon-thumbsup.svg");
  width: 37px;
  height: 28px;
  background-size: 25px;
  background-repeat: no-repeat;
  background-position: center center;
  display: inline-block;
}
.usps__ballonshop .icon-3 {
  background-image: url("../web/img/icon-balloon.svg");
  width: 22px;
  height: 27px;
  background-size: 16px;
  background-repeat: no-repeat;
  background-position: center center;
  display: inline-block;
}