@import url("https://use.typekit.net/whe0xte.css");
@import url("https://fonts.googleapis.com/css?family=Muli:300,400,700&display=swap");
html.page--categories .site-wrapper {
  background-color: #f5f8fb !important;
}
html.page--categories .site-wrapper .breadcrumb .widget-fullwidthimage img {
  border-radius: 0.25rem;
}
html.page--categories .site-wrapper h1 {
  color: #005bbe !important;
}
@media (max-width: 576px) {
  html.page--categories .site-wrapper main {
    margin-top: 15px;
  }
}

.product_listing {
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 50px;
  padding: 0 15px;
}
.product_listing .productsgrid__product--hidden {
  display: none;
}

.product__related .productsgrid .productitem__baseprice > .price {
  font-size: 16px;
}
.product__related .productsgrid .productitem__nowprice > span {
  font-size: 21px;
}

.productsgrid a, .productsgrid a p, .productsgrid a:hover p,
.categorygrid a,
.categorygrid a p,
.categorygrid a:hover p {
  color: #3a3e3e !important;
}
.productsgrid a:hover,
.categorygrid a:hover {
  text-decoration: none;
}
@media (max-width: 576px) {
  .productsgrid .col-6:nth-child(odd),
  .categorygrid .col-6:nth-child(odd) {
    padding-right: 5px;
  }
  .productsgrid .col-6:nth-child(even),
  .categorygrid .col-6:nth-child(even) {
    padding-left: 5px;
  }
}

.categorygrid:empty {
  display: none;
}

.productsgrid__product {
  margin-bottom: 15px;
}
.productsgrid__product.ribbon .productitem:before {
  width: 101%;
  height: 25px;
  content: "";
  display: inline-block;
  position: absolute;
  bottom: -24px;
  right: -2px;
  z-index: 1;
  background-color: #d46a00;
  pointer-events: none;
  border-radius: 0 0 4px 4px;
  font-size: 13px;
  font-weight: 700;
  color: #fff;
  text-align: center;
}

.productitem {
  font: inherit;
  cursor: pointer;
  transition: all 0.2s ease-in-out 0s;
  border: none;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  border: 1px solid #ccc;
  border-radius: 3px;
}
.productitem .badge-danger {
  background-color: #d13539;
  position: absolute;
  left: -5px;
  padding: 6px 9px;
  border-radius: 3px;
  top: 5px;
  text-transform: uppercase;
  font-size: 14px;
}
.productitem:hover, .productitem:focus {
  text-decoration: none;
  color: inherit;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
}
.productitem:hover .btn-primary,
.productitem:hover .btn-primary, .productitem:focus .btn-primary,
.productitem:focus .btn-primary {
  background-color: #ed7700;
  border-color: #ed7700;
}
.productitem .productitem__image {
  text-align: center;
  margin-bottom: 10px;
  min-height: 210px;
  display: flex;
  justify-content: center;
}
.productitem .productitem__image img {
  height: auto;
  align-self: start;
}
.productitem .card-body {
  display: flex;
  flex-direction: column;
}
.productitem .card-body .productitem__description {
  font-size: 14px;
  line-height: 19px;
}
@media (max-width: 576px) {
  .productitem .card-body .productitem__description {
    font-size: 12px;
    line-height: 18px;
  }
}
.productitem .card-footer {
  position: relative;
  background-color: rgba(0, 0, 0, 0);
  padding: 10px 0 0 0;
  border-top: 0;
  margin-top: auto;
}
.productitem__title {
  font-size: 18px;
  padding-bottom: 5px;
}
.productitem__discountslogan {
  color: #7abb14;
  font-weight: 600;
}
.productitem__price {
  align-content: center;
}
.productitem__baseprice > .price {
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  position: relative;
  color: #929292;
  margin-right: 5px;
}
.productitem__baseprice > .price:before {
  content: "";
  position: absolute;
  top: 11px;
  left: 0px;
  width: 100%;
  height: 9px;
  background: transparent;
  border-top: 1px solid #929292;
  border-radius: 0;
  transform: rotate(-11deg);
}
.productitem__baseprice + .productitem__nowprice {
  padding-top: 0;
}
.productitem__nowprice > span {
  display: inline-block;
  font-size: 21px;
  font-weight: 600;
  color: #000;
}
@media (max-width: 565px) {
  .productitem__nowprice > span {
    margin-bottom: 10px;
  }
}
.productitem__baseprice > span.pricemultiple, .productitem__nowprice > span.pricemultiple {
  font-size: inherit;
  font-weight: inherit;
}
.productitem .btn {
  position: absolute;
  right: 0;
  bottom: 0;
}
.productitem__message {
  color: #4aa814;
  font-weight: 600;
}

.categoryitem {
  font: inherit;
  cursor: pointer;
  transition: all 0.2s ease-in-out 0s;
}
.categoryitem:hover, .categoryitem:focus {
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.2) !important;
  -moz-box-shadow: 0 2px 7px rgba(0, 0, 0, 0.2) !important;
  -webkit-box-shadow: 0 2px 7px rgba(0, 0, 0, 0.2) !important;
  text-decoration: none;
  color: inherit;
}
.categoryitem .categoryitem__image {
  text-align: center;
  margin-bottom: 10px;
}
.categoryitem .categoryitem__image img {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.categoryitem .card-body {
  padding: 1rem;
}
.categoryitem .card-footer {
  position: relative;
  border-top: 0;
  background-color: rgba(0, 0, 0, 0.03);
}
.categoryitem__title {
  font-size: 24px;
}
@media (max-width: 576px) {
  .categoryitem__title {
    font-size: 18px;
    line-height: 100%;
  }
}
@media (max-width: 576px) {
  .categoryitem__description {
    font-size: 12px;
    line-height: 18px;
  }
}

@media (max-width: 565px) {
  .productsgrid__product:nth-child(odd) {
    padding-right: 5px;
    padding-left: 15px;
  }
  .productsgrid__product:nth-child(even) {
    padding-right: 15px;
    padding-left: 5px;
  }
  .productsgrid__product .productitem__image {
    min-height: 100px;
  }
  .productsgrid__product .card-body {
    padding: 10px;
  }
  .productsgrid__product .productitem .btn {
    position: inherit;
    width: 100%;
  }
}