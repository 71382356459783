@import url("https://use.typekit.net/whe0xte.css");
@import url("https://fonts.googleapis.com/css?family=Muli:300,400,700&display=swap");
.wh-pagination {
  list-style: none;
  margin: 0px 0 25px;
  padding: 0;
  border: 0 none;
}
.wh-pagination li {
  display: inline-block;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  text-align: center;
  border-radius: 3px;
}
.wh-pagination li + li {
  margin-left: 5px;
}
.wh-pagination span, .wh-pagination a {
  display: block;
  padding: 5px 12px;
}
.wh-pagination a {
  color: #005bbe !important;
  text-decoration: none;
  border-radius: 3px;
}
.wh-pagination a:hover {
  background-color: #005bbe !important;
  color: #fff !important;
}
.wh-pagination li.active {
  border: 1px solid #005bbe !important;
}
.wh-pagination li.active a {
  background-color: #005bbe !important;
  color: #fff !important;
}
.wh-pagination span::before {
  content: "...";
  display: inline-block;
}