.widget
{
  margin-bottom: 1rem;
}
  .widget--mobile
, .widget--desktop
{
  display: none;
}
@media(max-width: 767px)
{
  .widget--mobile
  {
    display: block;
  }
}
@media(min-width: 768px)
{
  .widget--desktop
  {
    display: block;
  }
}


.anchor
{
  display: block;
  position: relative;
  top: -150px;
  visibility: hidden;
}

aside#sidenav > ul > li > a
{
  font-size: 21px;
  color: #000000;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 400;
}
aside#sidenav ul li a
{
  color: #444444;
}
aside#sidenav ul ul li.active > a
{
  font-weight: 600;
}
aside#sidenav ul li a:before
{
  color:#005bbe;
}
/* productfilter(s) */
.productsgrid[data-filterconfig="null"] .widget-mobileproductfilter
{
  display:none;
}
.widget-productfilter
{
  background-color: #fdf8f4;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 15px;
}

#filterSideNav .widget-productfilter .aside__title
{
  color:#fff;
}
#filterSideNav .productfilter__activelabel {
  background-color: #777;
  color:#fff;
}
.productfilter__activelabel
{
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  border: 1px solid #ccc;
  padding: 5px 10px;
  font-size: 14px;
  margin-bottom: 3px;
}
.productfilter__activeremove:before
{
  content: "x";
  float: right;
  font-size: 18px;
  cursor: pointer;
  font-style: normal;
  color:#d13539;
}
.productfilter[data-type="checkbox"],
.productfilter[data-type="select"]
{
  max-height: 205px;
  overflow:hidden;
}
.productfilter + .productfilter
{
  border-top: 1px solid #e5e5e5;
}
.productfilter:last-child
{
  padding-bottom: 0;
}
#filterSideNav .productfilter + .productfilter
{
  border-top:0;
}

#filterSideNav .widget-productfilter {
  border-bottom:0;
}
#filterSideNav .productfilter-title,
#filterSideNav .productfilter--optionlist li label,
#filterSideNav .productfilter__activetitle,
#filterSideNav .wh-slider-holder > .wh-slider > .wh-slider-knob
{
  color:#fff;
}
#filterSideNav ul li {
  border-bottom:0!important;
}
#filterSideNav .productfilter--select {
  border:none;
}
.productfilter-title
{
  font-weight: bold;
  padding-bottom: 10px;
  padding: 10px 0;
  display: block;
  color:#000;

}
.productfilter-title[data-toggle="collapse"]:after {
  content: "\f106";
  font-family: FontAwesome;
  float: right;
  font-size: 18px;
}

.productfilter-title[data-toggle="collapse"].collapsed:after {
  content: "\f107";
  font-family: FontAwesome;
  float: right;
  font-size: 18px;
}

.productfilter--optionlist
{
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.productfilter--optionlist li
{
  display: flex;
  align-content: flex-start;
}
.productfilter--optionlist li input
{
  margin-top: 6px;
}
.productfilter--optionlist li label
{
  padding-left: 5px;
  margin-bottom: 3px;
}
.productfilter--select
{
  width: 100%;
  padding: 5px;
}
@media(max-width: 767px)
{
  #sidebar .widget-productfilter,
  .widget-categorylist
  {
    display: none;
  }
}
a.collapsebtn[aria-expanded="false"]:before{
  content:"Filter";
}

a.collapsebtn[aria-expanded="true"]:before{
  content:"Filter inklappen";
}

/**/
.widget-fullwidthimage a
{
  display: block;
  text-decoration: none;
}
.widget-fullwidthimage img
{
  width: 100%;
  height: auto;
  vertical-align: bottom;
}

/**/
.widget-categorieslist
{
  list-style-type: none;
  margin: 10px 0 20px;
  padding: 0;
}
.widget-categorieslist li
{
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  display: block;
  background-color: #fff;
  transition: background-color 0.2s;
}
.widget-categorieslist li + li
{
  margin-top: -1px;
}
.widget-categorieslist li:hover
{
  background-color: #f5f5f5;
}
.widget-categorieslist a
{
  display: block;
  color: #555;
  position: relative;
  padding: 10px 15px;
}
.widget-categorieslist a:after
{
  font-family: FontAwesome;
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 15px;
  margin-top: -10px;
  content: "\f105";
  font-size: 16px;
  color:#005bbe;
}
.widget-categorieslist a:hover
{
  text-decoration: none;
}

/* banner */
.widget-image
{
  border: 1px solid #ccc;
  border-radius: 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}
.widget-image__link
{
  color: #584a45;
}
.widget-image__link:hover
{
  color: inherit;
}
.widget-image img
{
  width: 100%;
  height: auto;
  vertical-align: bottom;
}

/* group for quicklink widgets */
.widget-widgetgroup
{
  display: flex;
  flex-flow: wrap;
  list-style-type: none;
  margin: 0 0 0 -30px;
  padding: 0;
}
.widget-widgetgroup > li
{
  flex: 1 1 50%;
  padding: 0 30px 20px 0px;
}
.widget-widgetgroup > li > .widget
{
  height: 100%;
  margin-bottom: 0;
}
@media( max-width: 600px )
{
  .widget-widgetgroup
  {
    display: block;
    margin-left: 0;
  }
  .widget-widgetgroup > li
  {
    flex: none;
    width: 100%;
    padding-left: 0;
  }
}

/* widget faq */
.widget-faq .card
{
  margin-bottom: 10px;
  border: 0;
  box-shadow: 0 1px 2px rgba(0,0,0,.2);
  -moz-box-shadow: 0 1px 2px rgba(0,0,0,.2);
  -webkit-box-shadow: 0 1px 2px rgba(0,0,0,.2);
  border-radius: 3px;
}
.widget-faq .card-header
{
  background-color: #fdf8f4;
}

.widget-faq .card .card-header
{
  padding:0;
  margin-bottom: 0;
}
.widget-faq .btn-link
{
  color:#50505c;
  display: block;
  width: 100%;
  text-align: left;
  padding: .5rem;
}
.widget-faq .card-header .btn:after
{
  font-family: 'FontAwesome';
  content: "\f068";
  float: right;
  font-size: 14px;
}
.widget-faq .card-header .btn.collapsed:after
{
  content: "\f067";
}

.widget-homebanner .row
{
  min-height:395px;
}
.widget-homebanner .homebannerimage
{
  background-position: center center;
  background-repeat: no-repeat;
}
.widget-homebanner h1
{
  font-size: 42px;
}
.widget-homebanner p
{
  font-size: 26px;
  font-family: 'futura-pt', sans-serif;
  font-weight: 300;
  line-height: 1.3;
}
@media (max-width: 565px)
{
  .widget-homebanner .row
  {
    min-height: 400px;
  }
  .widget-homebanner h1
  {
    font-size: 30px;
  }
  .widget-homebanner p
  {
    font-size: 21px;
  }
}

.widget-homebanner a:hover
{
  text-decoration: none;
}

.categorylist__list,
.subcategorylist__list
{
  display: block;
  margin: 0;
  overflow: hidden;
  padding: 0;
}
.subcategorylist__list
{
  display: none;
}
.categorylist__listitem,
.subcategorylist__listitem
{
  font-size: 15px;
}
.categorylist__listitem
{
  list-style-type: none;
  padding: 2px 0;
}
.subcategorylist__listitem
{
  list-style-type: none;
  padding: 0px 0px 0 20px;
  font-size: 13px;
}
.categorylist__list i
{
  color:#005bbe;
}
li.selected > .subcategorylist__list
{
  display: block;
}
li.selected > .subcategorylist__listitemlink {
  font-weight: 600;
  text-decoration: underline;
}
.categorylist__listitemlink,
.subcategorylist__listitemlink
{
  color: #58585a;
}