@import url("https://use.typekit.net/whe0xte.css");
@import url("https://fonts.googleapis.com/css?family=Muli:300,400,700&display=swap");
html.webshop--isballon .slick-slide {
  opacity: 1 !important;
}
html.webshop--isballon .product__images__preview__container {
  display: none;
}
@media (min-width: 992px) {
  html.webshop--isballon .product__images {
    position: sticky;
    top: 90px;
    min-height: 428px;
  }
}
@media (min-width: 320px) and (max-width: 420px) {
  html.webshop--isballon .product__options h2 {
    font-size: 21px;
  }
}
html.webshop--isballon .product__options .dd-select:not(.dd-open) + .dd-options {
  display: none !important;
}
html.webshop--isballon .product__options .option__ballon .dd-container .dd-options li,
html.webshop--isballon .product__options .option__kleur .dd-container .dd-options li {
  display: flex;
  order: 2;
}
html.webshop--isballon .product__options .option__ballon .dd-container .dd-options li:nth-child(1),
html.webshop--isballon .product__options .option__kleur .dd-container .dd-options li:nth-child(1) {
  order: 1;
}
html.webshop--isballon .product__options .option__ballon .dd-container .dd-options li:nth-child(2),
html.webshop--isballon .product__options .option__kleur .dd-container .dd-options li:nth-child(2) {
  order: 0;
}
html.webshop--isballon .product__options .option__ballon .dd-container .dd-options li.option__label,
html.webshop--isballon .product__options .option__kleur .dd-container .dd-options li.option__label {
  display: flex;
  margin-bottom: 5px;
  width: 100%;
}
html.webshop--isballon .product__options .option__ballon .dd-container .dd-options li.option__label .dd-option,
html.webshop--isballon .product__options .option__kleur .dd-container .dd-options li.option__label .dd-option {
  box-shadow: none;
  border: none;
  pointer-events: none;
  cursor: default;
  margin: 0;
}
html.webshop--isballon .product__options .option__ballon .dd-container .dd-options li.option__label .dd-option .dd-option-text,
html.webshop--isballon .product__options .option__kleur .dd-container .dd-options li.option__label .dd-option .dd-option-text {
  display: block;
  margin-bottom: 0;
  line-height: normal !important;
  margin-top: 5px;
}
html.webshop--isballon .product__options .option__kleur-1,
html.webshop--isballon .product__options .option__kleur-2,
html.webshop--isballon .product__options .option__kleur-3,
html.webshop--isballon .product__options .option__kleur-4,
html.webshop--isballon .product__options .option__ballon-1,
html.webshop--isballon .product__options .option__ballon-2,
html.webshop--isballon .product__options .option__ballon-3,
html.webshop--isballon .product__options .option__ballon-4,
html.webshop--isballon .product__options .option__kleur {
  float: left;
}
html.webshop--isballon .product__options .option__kleur-1 .dd-container .dd-pointer,
html.webshop--isballon .product__options .option__kleur-2 .dd-container .dd-pointer,
html.webshop--isballon .product__options .option__kleur-3 .dd-container .dd-pointer,
html.webshop--isballon .product__options .option__kleur-4 .dd-container .dd-pointer,
html.webshop--isballon .product__options .option__ballon-1 .dd-container .dd-pointer,
html.webshop--isballon .product__options .option__ballon-2 .dd-container .dd-pointer,
html.webshop--isballon .product__options .option__ballon-3 .dd-container .dd-pointer,
html.webshop--isballon .product__options .option__ballon-4 .dd-container .dd-pointer,
html.webshop--isballon .product__options .option__kleur .dd-container .dd-pointer {
  display: none;
}
html.webshop--isballon .product__options .option__kleur-1 .dd-container .dd-options li,
html.webshop--isballon .product__options .option__kleur-2 .dd-container .dd-options li,
html.webshop--isballon .product__options .option__kleur-3 .dd-container .dd-options li,
html.webshop--isballon .product__options .option__kleur-4 .dd-container .dd-options li,
html.webshop--isballon .product__options .option__ballon-1 .dd-container .dd-options li,
html.webshop--isballon .product__options .option__ballon-2 .dd-container .dd-options li,
html.webshop--isballon .product__options .option__ballon-3 .dd-container .dd-options li,
html.webshop--isballon .product__options .option__ballon-4 .dd-container .dd-options li,
html.webshop--isballon .product__options .option__kleur .dd-container .dd-options li {
  display: flex;
  order: 2;
}
html.webshop--isballon .product__options .option__kleur-1 .dd-container .dd-options li:nth-child(1),
html.webshop--isballon .product__options .option__kleur-2 .dd-container .dd-options li:nth-child(1),
html.webshop--isballon .product__options .option__kleur-3 .dd-container .dd-options li:nth-child(1),
html.webshop--isballon .product__options .option__kleur-4 .dd-container .dd-options li:nth-child(1),
html.webshop--isballon .product__options .option__ballon-1 .dd-container .dd-options li:nth-child(1),
html.webshop--isballon .product__options .option__ballon-2 .dd-container .dd-options li:nth-child(1),
html.webshop--isballon .product__options .option__ballon-3 .dd-container .dd-options li:nth-child(1),
html.webshop--isballon .product__options .option__ballon-4 .dd-container .dd-options li:nth-child(1),
html.webshop--isballon .product__options .option__kleur .dd-container .dd-options li:nth-child(1) {
  order: 1;
  display: none;
}
html.webshop--isballon .product__options .option__kleur-1 .dd-container .dd-options li:nth-child(2),
html.webshop--isballon .product__options .option__kleur-2 .dd-container .dd-options li:nth-child(2),
html.webshop--isballon .product__options .option__kleur-3 .dd-container .dd-options li:nth-child(2),
html.webshop--isballon .product__options .option__kleur-4 .dd-container .dd-options li:nth-child(2),
html.webshop--isballon .product__options .option__ballon-1 .dd-container .dd-options li:nth-child(2),
html.webshop--isballon .product__options .option__ballon-2 .dd-container .dd-options li:nth-child(2),
html.webshop--isballon .product__options .option__ballon-3 .dd-container .dd-options li:nth-child(2),
html.webshop--isballon .product__options .option__ballon-4 .dd-container .dd-options li:nth-child(2),
html.webshop--isballon .product__options .option__kleur .dd-container .dd-options li:nth-child(2) {
  order: 2;
}
html.webshop--isballon .product__options .option__kleur-1 .dd-container .dd-options li:nth-child(3),
html.webshop--isballon .product__options .option__kleur-2 .dd-container .dd-options li:nth-child(3),
html.webshop--isballon .product__options .option__kleur-3 .dd-container .dd-options li:nth-child(3),
html.webshop--isballon .product__options .option__kleur-4 .dd-container .dd-options li:nth-child(3),
html.webshop--isballon .product__options .option__ballon-1 .dd-container .dd-options li:nth-child(3),
html.webshop--isballon .product__options .option__ballon-2 .dd-container .dd-options li:nth-child(3),
html.webshop--isballon .product__options .option__ballon-3 .dd-container .dd-options li:nth-child(3),
html.webshop--isballon .product__options .option__ballon-4 .dd-container .dd-options li:nth-child(3),
html.webshop--isballon .product__options .option__kleur .dd-container .dd-options li:nth-child(3) {
  order: 0;
}
html.webshop--isballon .product__options .option__kleur-1 .dd-container .dd-options li.option__label,
html.webshop--isballon .product__options .option__kleur-2 .dd-container .dd-options li.option__label,
html.webshop--isballon .product__options .option__kleur-3 .dd-container .dd-options li.option__label,
html.webshop--isballon .product__options .option__kleur-4 .dd-container .dd-options li.option__label,
html.webshop--isballon .product__options .option__ballon-1 .dd-container .dd-options li.option__label,
html.webshop--isballon .product__options .option__ballon-2 .dd-container .dd-options li.option__label,
html.webshop--isballon .product__options .option__ballon-3 .dd-container .dd-options li.option__label,
html.webshop--isballon .product__options .option__ballon-4 .dd-container .dd-options li.option__label,
html.webshop--isballon .product__options .option__kleur .dd-container .dd-options li.option__label {
  display: flex;
  margin-bottom: 5px;
  width: 100%;
}
html.webshop--isballon .product__options .option__kleur-1 .dd-container .dd-options li.option__label .dd-option,
html.webshop--isballon .product__options .option__kleur-2 .dd-container .dd-options li.option__label .dd-option,
html.webshop--isballon .product__options .option__kleur-3 .dd-container .dd-options li.option__label .dd-option,
html.webshop--isballon .product__options .option__kleur-4 .dd-container .dd-options li.option__label .dd-option,
html.webshop--isballon .product__options .option__ballon-1 .dd-container .dd-options li.option__label .dd-option,
html.webshop--isballon .product__options .option__ballon-2 .dd-container .dd-options li.option__label .dd-option,
html.webshop--isballon .product__options .option__ballon-3 .dd-container .dd-options li.option__label .dd-option,
html.webshop--isballon .product__options .option__ballon-4 .dd-container .dd-options li.option__label .dd-option,
html.webshop--isballon .product__options .option__kleur .dd-container .dd-options li.option__label .dd-option {
  box-shadow: none;
  border: none;
  pointer-events: none;
  cursor: default;
  margin: 0;
}
html.webshop--isballon .product__options .option__kleur-1 .dd-container .dd-options li.option__label .dd-option .dd-option-text,
html.webshop--isballon .product__options .option__kleur-2 .dd-container .dd-options li.option__label .dd-option .dd-option-text,
html.webshop--isballon .product__options .option__kleur-3 .dd-container .dd-options li.option__label .dd-option .dd-option-text,
html.webshop--isballon .product__options .option__kleur-4 .dd-container .dd-options li.option__label .dd-option .dd-option-text,
html.webshop--isballon .product__options .option__ballon-1 .dd-container .dd-options li.option__label .dd-option .dd-option-text,
html.webshop--isballon .product__options .option__ballon-2 .dd-container .dd-options li.option__label .dd-option .dd-option-text,
html.webshop--isballon .product__options .option__ballon-3 .dd-container .dd-options li.option__label .dd-option .dd-option-text,
html.webshop--isballon .product__options .option__ballon-4 .dd-container .dd-options li.option__label .dd-option .dd-option-text,
html.webshop--isballon .product__options .option__kleur .dd-container .dd-options li.option__label .dd-option .dd-option-text {
  display: block;
  margin-bottom: 0;
  line-height: normal !important;
  margin-top: 5px;
}
@media (min-width: 320px) and (max-width: 420px) {
  html.webshop--isballon .product__options .option__kleur-2 .dd-container .dd-options,
  html.webshop--isballon .product__options .option__ballon-2 .dd-container .dd-options {
    left: -67px;
  }
}
@media (min-width: 320px) and (max-width: 420px) {
  html.webshop--isballon .product__options .option__kleur-3 .dd-container .dd-options,
  html.webshop--isballon .product__options .option__ballon-3 .dd-container .dd-options {
    left: -123px;
  }
}
html.webshop--isballon .product__options .option__kleur-4,
html.webshop--isballon .product__options .option__ballon-4 {
  margin-right: 0;
}
html.webshop--isballon .product__options .option__kleur-4 .dd-container .dd-options,
html.webshop--isballon .product__options .option__ballon-4 .dd-container .dd-options {
  left: -180px;
}
html.webshop--isballon .product__options .option__kleur,
html.webshop--isballon .product__options .option__kleur-1,
html.webshop--isballon .product__options .option__kleur-2,
html.webshop--isballon .product__options .option__kleur-3,
html.webshop--isballon .product__options .option__kleur-4,
html.webshop--isballon .product__options .option__ballon,
html.webshop--isballon .product__options .option__ballon-1,
html.webshop--isballon .product__options .option__ballon-2,
html.webshop--isballon .product__options .option__ballon-3,
html.webshop--isballon .product__options .option__ballon-4 {
  margin-right: 1%;
  width: 24% !important;
  max-width: 85px;
}
html.webshop--isballon .product__options .option__kleur .dd-container,
html.webshop--isballon .product__options .option__kleur-1 .dd-container,
html.webshop--isballon .product__options .option__kleur-2 .dd-container,
html.webshop--isballon .product__options .option__kleur-3 .dd-container,
html.webshop--isballon .product__options .option__kleur-4 .dd-container,
html.webshop--isballon .product__options .option__ballon .dd-container,
html.webshop--isballon .product__options .option__ballon-1 .dd-container,
html.webshop--isballon .product__options .option__ballon-2 .dd-container,
html.webshop--isballon .product__options .option__ballon-3 .dd-container,
html.webshop--isballon .product__options .option__ballon-4 .dd-container {
  box-shadow: none !important;
}
html.webshop--isballon .product__options .option__kleur .dd-container .dd-options,
html.webshop--isballon .product__options .option__kleur-1 .dd-container .dd-options,
html.webshop--isballon .product__options .option__kleur-2 .dd-container .dd-options,
html.webshop--isballon .product__options .option__kleur-3 .dd-container .dd-options,
html.webshop--isballon .product__options .option__kleur-4 .dd-container .dd-options,
html.webshop--isballon .product__options .option__ballon .dd-container .dd-options,
html.webshop--isballon .product__options .option__ballon-1 .dd-container .dd-options,
html.webshop--isballon .product__options .option__ballon-2 .dd-container .dd-options,
html.webshop--isballon .product__options .option__ballon-3 .dd-container .dd-options,
html.webshop--isballon .product__options .option__ballon-4 .dd-container .dd-options {
  position: absolute;
  width: 260px !important;
  padding: 10px 0 5px 11px;
  background-color: #fff !important;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  border-top: 1px solid #ccc;
}
html.webshop--isballon .product__options .option__kleur .dd-container .dd-options li .dd-option,
html.webshop--isballon .product__options .option__kleur-1 .dd-container .dd-options li .dd-option,
html.webshop--isballon .product__options .option__kleur-2 .dd-container .dd-options li .dd-option,
html.webshop--isballon .product__options .option__kleur-3 .dd-container .dd-options li .dd-option,
html.webshop--isballon .product__options .option__kleur-4 .dd-container .dd-options li .dd-option,
html.webshop--isballon .product__options .option__ballon .dd-container .dd-options li .dd-option,
html.webshop--isballon .product__options .option__ballon-1 .dd-container .dd-options li .dd-option,
html.webshop--isballon .product__options .option__ballon-2 .dd-container .dd-options li .dd-option,
html.webshop--isballon .product__options .option__ballon-3 .dd-container .dd-options li .dd-option,
html.webshop--isballon .product__options .option__ballon-4 .dd-container .dd-options li .dd-option {
  display: block;
  padding: 0;
  border: 2px solid transparent;
  margin: 0 6px 4px 0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}
html.webshop--isballon .product__options .option__kleur .dd-container .dd-options li .dd-option.dd-option-selected,
html.webshop--isballon .product__options .option__kleur-1 .dd-container .dd-options li .dd-option.dd-option-selected,
html.webshop--isballon .product__options .option__kleur-2 .dd-container .dd-options li .dd-option.dd-option-selected,
html.webshop--isballon .product__options .option__kleur-3 .dd-container .dd-options li .dd-option.dd-option-selected,
html.webshop--isballon .product__options .option__kleur-4 .dd-container .dd-options li .dd-option.dd-option-selected,
html.webshop--isballon .product__options .option__ballon .dd-container .dd-options li .dd-option.dd-option-selected,
html.webshop--isballon .product__options .option__ballon-1 .dd-container .dd-options li .dd-option.dd-option-selected,
html.webshop--isballon .product__options .option__ballon-2 .dd-container .dd-options li .dd-option.dd-option-selected,
html.webshop--isballon .product__options .option__ballon-3 .dd-container .dd-options li .dd-option.dd-option-selected,
html.webshop--isballon .product__options .option__ballon-4 .dd-container .dd-options li .dd-option.dd-option-selected {
  border: 2px solid #75b325;
}
html.webshop--isballon .product__options .option__kleur .dd-container .dd-options li .dd-option .dd-option-image,
html.webshop--isballon .product__options .option__kleur-1 .dd-container .dd-options li .dd-option .dd-option-image,
html.webshop--isballon .product__options .option__kleur-2 .dd-container .dd-options li .dd-option .dd-option-image,
html.webshop--isballon .product__options .option__kleur-3 .dd-container .dd-options li .dd-option .dd-option-image,
html.webshop--isballon .product__options .option__kleur-4 .dd-container .dd-options li .dd-option .dd-option-image,
html.webshop--isballon .product__options .option__ballon .dd-container .dd-options li .dd-option .dd-option-image,
html.webshop--isballon .product__options .option__ballon-1 .dd-container .dd-options li .dd-option .dd-option-image,
html.webshop--isballon .product__options .option__ballon-2 .dd-container .dd-options li .dd-option .dd-option-image,
html.webshop--isballon .product__options .option__ballon-3 .dd-container .dd-options li .dd-option .dd-option-image,
html.webshop--isballon .product__options .option__ballon-4 .dd-container .dd-options li .dd-option .dd-option-image {
  float: none;
  max-width: 30px;
  width: 100%;
  margin-right: 0;
  background-color: #fff !important;
}
html.webshop--isballon .product__options .option__kleur .dd-container .dd-options li .dd-option .dd-option-text,
html.webshop--isballon .product__options .option__kleur-1 .dd-container .dd-options li .dd-option .dd-option-text,
html.webshop--isballon .product__options .option__kleur-2 .dd-container .dd-options li .dd-option .dd-option-text,
html.webshop--isballon .product__options .option__kleur-3 .dd-container .dd-options li .dd-option .dd-option-text,
html.webshop--isballon .product__options .option__kleur-4 .dd-container .dd-options li .dd-option .dd-option-text,
html.webshop--isballon .product__options .option__ballon .dd-container .dd-options li .dd-option .dd-option-text,
html.webshop--isballon .product__options .option__ballon-1 .dd-container .dd-options li .dd-option .dd-option-text,
html.webshop--isballon .product__options .option__ballon-2 .dd-container .dd-options li .dd-option .dd-option-text,
html.webshop--isballon .product__options .option__ballon-3 .dd-container .dd-options li .dd-option .dd-option-text,
html.webshop--isballon .product__options .option__ballon-4 .dd-container .dd-options li .dd-option .dd-option-text {
  display: none;
}
@media (max-width: 767px) {
  html.webshop--isballon .product__options .option__kleur .dd-container .dd-options,
  html.webshop--isballon .product__options .option__kleur-1 .dd-container .dd-options,
  html.webshop--isballon .product__options .option__kleur-2 .dd-container .dd-options,
  html.webshop--isballon .product__options .option__kleur-3 .dd-container .dd-options,
  html.webshop--isballon .product__options .option__kleur-4 .dd-container .dd-options,
  html.webshop--isballon .product__options .option__ballon .dd-container .dd-options,
  html.webshop--isballon .product__options .option__ballon-1 .dd-container .dd-options,
  html.webshop--isballon .product__options .option__ballon-2 .dd-container .dd-options,
  html.webshop--isballon .product__options .option__ballon-3 .dd-container .dd-options,
  html.webshop--isballon .product__options .option__ballon-4 .dd-container .dd-options {
    z-index: 10;
    bottom: 75px;
  }
}
html.webshop--isballon .product__options .option__kleur .dd-container .dd-select,
html.webshop--isballon .product__options .option__kleur-1 .dd-container .dd-select,
html.webshop--isballon .product__options .option__kleur-2 .dd-container .dd-select,
html.webshop--isballon .product__options .option__kleur-3 .dd-container .dd-select,
html.webshop--isballon .product__options .option__kleur-4 .dd-container .dd-select,
html.webshop--isballon .product__options .option__ballon .dd-container .dd-select,
html.webshop--isballon .product__options .option__ballon-1 .dd-container .dd-select,
html.webshop--isballon .product__options .option__ballon-2 .dd-container .dd-select,
html.webshop--isballon .product__options .option__ballon-3 .dd-container .dd-select,
html.webshop--isballon .product__options .option__ballon-4 .dd-container .dd-select {
  cursor: pointer;
  width: 95% !important;
  max-width: 80px;
  text-align: center;
  -webkit-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  -moz-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
@media (min-width: 320px) and (max-width: 400px) {
  html.webshop--isballon .product__options .option__kleur .dd-container .dd-select,
  html.webshop--isballon .product__options .option__kleur-1 .dd-container .dd-select,
  html.webshop--isballon .product__options .option__kleur-2 .dd-container .dd-select,
  html.webshop--isballon .product__options .option__kleur-3 .dd-container .dd-select,
  html.webshop--isballon .product__options .option__kleur-4 .dd-container .dd-select,
  html.webshop--isballon .product__options .option__ballon .dd-container .dd-select,
  html.webshop--isballon .product__options .option__ballon-1 .dd-container .dd-select,
  html.webshop--isballon .product__options .option__ballon-2 .dd-container .dd-select,
  html.webshop--isballon .product__options .option__ballon-3 .dd-container .dd-select,
  html.webshop--isballon .product__options .option__ballon-4 .dd-container .dd-select {
    height: 90px;
  }
}
@media (min-width: 320px) and (max-width: 400px) {
  html.webshop--isballon .product__options .option__kleur .dd-container .dd-select .dd-selected,
  html.webshop--isballon .product__options .option__kleur-1 .dd-container .dd-select .dd-selected,
  html.webshop--isballon .product__options .option__kleur-2 .dd-container .dd-select .dd-selected,
  html.webshop--isballon .product__options .option__kleur-3 .dd-container .dd-select .dd-selected,
  html.webshop--isballon .product__options .option__kleur-4 .dd-container .dd-select .dd-selected,
  html.webshop--isballon .product__options .option__ballon .dd-container .dd-select .dd-selected,
  html.webshop--isballon .product__options .option__ballon-1 .dd-container .dd-select .dd-selected,
  html.webshop--isballon .product__options .option__ballon-2 .dd-container .dd-select .dd-selected,
  html.webshop--isballon .product__options .option__ballon-3 .dd-container .dd-select .dd-selected,
  html.webshop--isballon .product__options .option__ballon-4 .dd-container .dd-select .dd-selected {
    padding: 3px;
  }
}
html.webshop--isballon .product__options .option__kleur .dd-container .dd-select .dd-selected .dd-selected-image,
html.webshop--isballon .product__options .option__kleur-1 .dd-container .dd-select .dd-selected .dd-selected-image,
html.webshop--isballon .product__options .option__kleur-2 .dd-container .dd-select .dd-selected .dd-selected-image,
html.webshop--isballon .product__options .option__kleur-3 .dd-container .dd-select .dd-selected .dd-selected-image,
html.webshop--isballon .product__options .option__kleur-4 .dd-container .dd-select .dd-selected .dd-selected-image,
html.webshop--isballon .product__options .option__ballon .dd-container .dd-select .dd-selected .dd-selected-image,
html.webshop--isballon .product__options .option__ballon-1 .dd-container .dd-select .dd-selected .dd-selected-image,
html.webshop--isballon .product__options .option__ballon-2 .dd-container .dd-select .dd-selected .dd-selected-image,
html.webshop--isballon .product__options .option__ballon-3 .dd-container .dd-select .dd-selected .dd-selected-image,
html.webshop--isballon .product__options .option__ballon-4 .dd-container .dd-select .dd-selected .dd-selected-image {
  float: none !important;
}
html.webshop--isballon .product__options .option__kleur .dd-container .dd-select .dd-selected .dd-selected-text,
html.webshop--isballon .product__options .option__kleur-1 .dd-container .dd-select .dd-selected .dd-selected-text,
html.webshop--isballon .product__options .option__kleur-2 .dd-container .dd-select .dd-selected .dd-selected-text,
html.webshop--isballon .product__options .option__kleur-3 .dd-container .dd-select .dd-selected .dd-selected-text,
html.webshop--isballon .product__options .option__kleur-4 .dd-container .dd-select .dd-selected .dd-selected-text,
html.webshop--isballon .product__options .option__ballon .dd-container .dd-select .dd-selected .dd-selected-text,
html.webshop--isballon .product__options .option__ballon-1 .dd-container .dd-select .dd-selected .dd-selected-text,
html.webshop--isballon .product__options .option__ballon-2 .dd-container .dd-select .dd-selected .dd-selected-text,
html.webshop--isballon .product__options .option__ballon-3 .dd-container .dd-select .dd-selected .dd-selected-text,
html.webshop--isballon .product__options .option__ballon-4 .dd-container .dd-select .dd-selected .dd-selected-text {
  line-height: 12px !important;
  font-size: 12px;
  margin-top: 5px;
  cursor: pointer;
  display: block;
}
@media (min-width: 320px) and (max-width: 400px) {
  html.webshop--isballon .product__options .option__kleur .dd-container .dd-select .dd-selected .dd-selected-text,
  html.webshop--isballon .product__options .option__kleur-1 .dd-container .dd-select .dd-selected .dd-selected-text,
  html.webshop--isballon .product__options .option__kleur-2 .dd-container .dd-select .dd-selected .dd-selected-text,
  html.webshop--isballon .product__options .option__kleur-3 .dd-container .dd-select .dd-selected .dd-selected-text,
  html.webshop--isballon .product__options .option__kleur-4 .dd-container .dd-select .dd-selected .dd-selected-text,
  html.webshop--isballon .product__options .option__ballon .dd-container .dd-select .dd-selected .dd-selected-text,
  html.webshop--isballon .product__options .option__ballon-1 .dd-container .dd-select .dd-selected .dd-selected-text,
  html.webshop--isballon .product__options .option__ballon-2 .dd-container .dd-select .dd-selected .dd-selected-text,
  html.webshop--isballon .product__options .option__ballon-3 .dd-container .dd-select .dd-selected .dd-selected-text,
  html.webshop--isballon .product__options .option__ballon-4 .dd-container .dd-select .dd-selected .dd-selected-text {
    line-height: 14px !important;
    font-size: 10px;
    word-break: break-word;
  }
}
html.webshop--isballon .product__options .alert {
  line-height: 18px;
  padding: 10px;
}
html.webshop--isballon .product__options .printtypebox .option__opdrukkleur .dd-container .dd-options,
html.webshop--isballon .product__options .printtypebox .option__kleur-gewicht .dd-container .dd-options {
  position: inherit;
  padding: 10px 0 5px 11px;
  background-color: #fff !important;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
html.webshop--isballon .product__options .printtypebox .option__opdrukkleur .dd-container .dd-options li,
html.webshop--isballon .product__options .printtypebox .option__kleur-gewicht .dd-container .dd-options li {
  display: flex;
}
html.webshop--isballon .product__options .printtypebox .option__opdrukkleur .dd-container .dd-options li .dd-option,
html.webshop--isballon .product__options .printtypebox .option__kleur-gewicht .dd-container .dd-options li .dd-option {
  display: block;
  padding: 0;
  border: 2px solid transparent;
  margin: 0 8px 8px 0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}
html.webshop--isballon .product__options .printtypebox .option__opdrukkleur .dd-container .dd-options li .dd-option.dd-option-selected,
html.webshop--isballon .product__options .printtypebox .option__kleur-gewicht .dd-container .dd-options li .dd-option.dd-option-selected {
  border: 2px solid #75b325;
}
html.webshop--isballon .product__options .printtypebox .option__opdrukkleur .dd-container .dd-options li .dd-option .dd-option-image,
html.webshop--isballon .product__options .printtypebox .option__kleur-gewicht .dd-container .dd-options li .dd-option .dd-option-image {
  float: none;
  max-width: 30px;
  width: 100%;
  margin-right: 0;
  background-color: #fff !important;
}
html.webshop--isballon .product__options .printtypebox .option__opdrukkleur .dd-container .dd-options li .dd-option .dd-option-text,
html.webshop--isballon .product__options .printtypebox .option__kleur-gewicht .dd-container .dd-options li .dd-option .dd-option-text {
  display: none;
}
html.webshop--isballon .product__options .option__opdrukkleur,
html.webshop--isballon .product__options .option__kleur-gewicht {
  clear: both;
}
html.webshop--isballon .product__options .option__opdrukkleur .dd-container .dd-options,
html.webshop--isballon .product__options .option__kleur-gewicht .dd-container .dd-options {
  position: inherit;
  padding: 10px 0 5px 11px;
  background-color: #fff !important;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
html.webshop--isballon .product__options .option__opdrukkleur .dd-container .dd-options li,
html.webshop--isballon .product__options .option__kleur-gewicht .dd-container .dd-options li {
  display: flex;
}
html.webshop--isballon .product__options .option__opdrukkleur .dd-container .dd-options li .dd-option,
html.webshop--isballon .product__options .option__kleur-gewicht .dd-container .dd-options li .dd-option {
  display: block;
  padding: 0;
  border: 2px solid transparent;
  margin: 0 8px 8px 0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}
html.webshop--isballon .product__options .option__opdrukkleur .dd-container .dd-options li .dd-option.dd-option-selected,
html.webshop--isballon .product__options .option__kleur-gewicht .dd-container .dd-options li .dd-option.dd-option-selected {
  border: 2px solid #75b325;
}
html.webshop--isballon .product__options .option__opdrukkleur .dd-container .dd-options li .dd-option .dd-option-image,
html.webshop--isballon .product__options .option__kleur-gewicht .dd-container .dd-options li .dd-option .dd-option-image {
  float: none;
  max-width: 30px;
  width: 100%;
  margin-right: 0;
  background-color: #fff !important;
}
html.webshop--isballon .product__options .option__opdrukkleur .dd-container .dd-options li .dd-option .dd-option-text,
html.webshop--isballon .product__options .option__kleur-gewicht .dd-container .dd-options li .dd-option .dd-option-text {
  display: none;
}
html.webshop--isballon #bs__decoration .decoratie__preview div img {
  width: 25%;
  float: left;
}
html.webshop--isballon .webshop-product__option--hidden {
  display: none;
}

html.reuze-helium-ballonnen .product__images__preview__container,
html.reuze-helium-ballonnen-bedrukt .product__images__preview__container,
html.helium-ballonnen .product__images__preview__container,
html.bedrukte-ballonnen .product__images__preview__container,
html.helium-ballonnen-bedrukt .product__images__preview__container,
html.ballon .product__images__preview__container,
html.reuzenballon .product__images__preview__container,
html.reuzenballonnen .product__images__preview__container {
  display: block;
  position: absolute;
  z-index: 1;
  top: 0;
}
html.reuze-helium-ballonnen.ddslick-initialized .product__images__preview__container,
html.reuze-helium-ballonnen-bedrukt.ddslick-initialized .product__images__preview__container,
html.helium-ballonnen.ddslick-initialized .product__images__preview__container,
html.bedrukte-ballonnen.ddslick-initialized .product__images__preview__container,
html.helium-ballonnen-bedrukt.ddslick-initialized .product__images__preview__container,
html.ballon.ddslick-initialized .product__images__preview__container,
html.reuzenballon.ddslick-initialized .product__images__preview__container,
html.reuzenballonnen.ddslick-initialized .product__images__preview__container {
  position: inherit;
}
html.reuze-helium-ballonnen.ddslick--selected .product__images__preview__container,
html.reuze-helium-ballonnen-bedrukt.ddslick--selected .product__images__preview__container,
html.helium-ballonnen.ddslick--selected .product__images__preview__container,
html.bedrukte-ballonnen.ddslick--selected .product__images__preview__container,
html.helium-ballonnen-bedrukt.ddslick--selected .product__images__preview__container,
html.ballon.ddslick--selected .product__images__preview__container,
html.reuzenballon.ddslick--selected .product__images__preview__container,
html.reuzenballonnen.ddslick--selected .product__images__preview__container {
  display: none;
}